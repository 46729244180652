.chatboxcss {
    border-radius: 11px;
    height: 63vh;
    background-color: #ECECF4;
    padding: 6px;
    overflow: scroll;
}
.chatMemeberBox{
    margin-top: 10px;
    border-radius: 13px;
    height: 8vh;
    background-color: #ECECF4;
}
.newTopicCard{
    width: 100%;
    background-color: #ECECF4;
    padding: 10px 6px 10px 8px;
    border-radius: 7px;
    border-style: none;
}
.newTopic{
    border-style: none;
    border-radius: 10px;
}
.userImgClass{
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.figureClass{
display: flex ;
}
.imageText{
    font-size: 10px;
    padding-left: 5px;
}
.timeImage{
    height: 15px;
    width: 12px;
    margin-right: 4px;
    background-color: #ECECF4;;
}
.timeText{
    font-size: 10px;
}

.messagerImgClass{
    width: 30px;
    height: 30px;
    margin-right: 7px;
    margin-left: 10px;
    border-radius: 50%;
}
.responseText {
    border-radius: 13px;
    border: 1px solid #bdb5b5;
}
.line{
    background-color: #908c8c;
    height: 1px;
    display: block;
    width: 80%;
    margin: 0 auto 15px;
}
.figurediv{
    
}