 .sidebar-offcanvas .active {
     color: #0000ff;
     font-weight: 600;
     background-color: inherit;
 }
 
 .sideNav .active,
 .sideNav .active:hover {
     background-color: inherit;
     border-radius: inherit;
     color: #0000ff;
     font-weight: 600;
 }
 
 .sideNav p {
     margin-bottom: 0;
     font-size: 16px;
     padding: 2px 0px;
     cursor: pointer;
 }
 
 .sideNav ul {
     padding: 3px 0px 3px 25px;
 }
 
 .sideNav p i {
     margin-left: 10px;
     transition: all 0.4s ease;
     font-size: 20px;
     vertical-align: bottom;
 }
 
 .sideNav p i.down {
     transform: rotateZ(-180deg);
 }
 /* .sideNav p i.fa-caret-down::before,
 .sideNav p i.fa-caret-up {
     transition: all 5s ease;
 } */