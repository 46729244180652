.trackerActivities{
    padding: 40px 0;
}
.trackerActivities .heading{
    margin-bottom: 40px;
}
.trackerActivities .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.trackerActivities .header{
    margin-bottom: 45px;
}
.trackerActivities .header .btnGrp{
    display: flex;
    flex-direction: row;
}
.trackerActivities .header .btnGrp .iconBtn{
    border: 1px solid #a1bad0;
    color: #a1bad0;
    margin-right: 20px;
    outline: none;
}
.trackerActivities .header .btnDropdown button{
    background-color: #f0f1f4;
    border: none;
    outline: none;
    color: #000000;
}
.trackerActivities .header .btnDropdown button:hover{
    border: 1px solid #a1bad0;
    outline: none;
}
.trackerActivities .header .btnDropdown span{
    font-size: 15px;
    margin-right: 12px;
}
.trackerActivities .header .btnDropdown span svg{
    color: #a1bad0;
    margin-right: 5px;
}
.closeBtn{
    right: 0 !important;
    position: absolute !important;
    outline: none !important;
}
.timeEntryDialog .MuiDialogTitle-root{
    padding: 30px 30px 10px 30px;
}
.timeEntryDialog .dialogContent{
    padding-bottom: 30px;
}
.timeEntryDialog .dialogContent .row{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.timeEntryDialog .dialogContent .inputLabel{
    color: #a1bad0;
}
.timeEntryDialog .dialogContent .inputField{
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
    margin-bottom: 25px;
}
.timeEntryDialog .dialogContent .activityDescription{
    width: 100%;
}
.timeEntryDialog .dialogContent .dateField{
    width: 68%;
    margin-bottom: 25px;
}
.timeEntryDialog .dialogContent .timeField{
    width: 35%;
    margin: 0 10px 25px 0px;
}
.timeEntryDialog .dialogContent .timeFieldEnd{
    margin-left: 10px;
    width: 35%;
}

.timeEntryDialog .dialogContent .submitBtn{
    margin: 40px 0 10px 0;
    float: right;
    padding: 12px;
}
.activtiesBlock{
    margin-bottom: 35px;
}
.activitiesBlock .title{
    font-size: 12px;
}
.activitiesCard{
    border: 1px solid #a1bad0 ;
    border-right: none;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.activitiesCard .timeBlock{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.activitiesCard .timeBlock .activitiesBtn, .activitiesCard .timeBlock .expandBtn{
    width: 30px;
    height: 30px;
    border: 2px solid #e6e6e6;
    border-radius: 25px;
    left: -30px;
    position: absolute;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.activitiesCard .timeBlock .activitiesBtn .activitiesNo{
    color: #c4b7b7;
    font-size: 15px;
    font-weight: bolder;
}
.activitiesCard .timeBlock .expandBtn{
    visibility: hidden;
}
.activitiesCard .timeBlock .expandBtn .expandIcon{
    color: #3863a3;
}
.activitiesCard:hover  .activitiesBtn{
    display: none;
}
.activitiesCard:hover .expandBtn{
    visibility: visible;
}
/* .activitiesCard:hover +  .expandBtn{
    visibility: visible;
}
.activitiesCard:hover + .activitiesBtn{
    visibility: hidden;
} */
.activitiesCard .projectBlock{
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.activitiesCard .timeText{
    font-size: 14px;
}
.activitiesCard .verticalLines{
    border-left: 2px solid #e6e6e6;
    height: 50px;
}
.activitiesCard .screenshotsBlock{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.activitiesCard .screenshotsBlock .screenShotIcon{
    color: #e6e6e6;
    font-size: 30px;
    margin: 0 20px;
}
.extendingBtn{
    width: 100%;
}
