.dashboarwrapper {
    padding: 175px 30px 30px;
    height: 1000px;
}

.sidebar {
    background-color: #fff;
    border-right: 1px solid #95989a;
    padding: 42px 30px 0px;
    z-index: 99;
    height: 100vh;
    top: 90px;
    position: fixed;
}

.card-header {
    background-image: linear-gradient(#819b5d, #1dc598);
    border-radius: 8px !important;
    margin-top: -6px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
}
a{
    color: black;
}
a:hover {
    text-decoration: none;
    color: black;
}
.adminbody {
    background-color: #dad9e9;
    width: 100%;
}

.dashboarwrapper .card {
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    cursor: pointer;
}

.dashboarwrapper .card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
