 .setting { 
    /* height: 1000px; */
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 95px 12px;
}
.setting .active, .setting .active:hover {
    background-color: inherit; 
    color: inherit;
}

.setting .nav-tabs { 
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    font-size: 18px;
}
.setting .nav-link {
    font-size: 15px;
}
.setting .tab-content {
    padding-top: 30px;
}
.setting .settingard {
    background-color: #FFFFFF;
    height: 64vh;
    margin: 0px 30px;
    padding: 30px 20px;
    position: relative;

}
.settingard figure img {
    border: 1px solid #707070;
    padding: 15px;
    margin-bottom: 10px;
    max-width: 160px;
}

/* payment page css start */

.paymentPage {
    background-color: #fff; 
    height: 70vh;
    margin: 0px 30px;
    padding: 30px 20px;
  }
  
  .paymentPage h5 {
    color: #000;
    font-weight: 400;
  }
  .paymentPage .offer {
      color: #FFB600;
  }
  .paymentPage .logo img {
    margin: 15px 20px;
    width: 100px;
  }
  
  .paymentPage .procing-logo {
    margin-bottom: 40px;
    width: 100px;
    height: 100px;
    background: #F1F1F1;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    margin-left: 42px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .paymentPage .procing-logo img {
    width: 60px;
    height: 60px; 
  }
  .paymentPage h6 {
    color: #2C3E50;
    font-weight: 300;
    font-size: 14px;
  }
  
  .leftContent {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .paymentPage .procing-logo p {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
  
  }
  .paymentPage .card {
    max-width: 85%;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #F1F1F1;
  }
  .paymentPage p {
    color: #2C3E50;
    font-size: 15px;
  }
  .paymentPage .borderTB {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;  
  }
  .paymentPage .borderT {
    border-top: 1px solid #dee2e6;
    font-weight: 600;
    margin-bottom: 0;
    padding: 5px 10px;
  }
  .paymentPage .borderTB p {
    margin-bottom: 0;
    padding: 5px 0px;
    font-weight: 600;
  }
  .underline {
    margin-bottom: 0;
    padding-top: 30px;
    text-decoration: underline;
  }
  .paymentPage button {
    background-color: #F1F1F1;
    margin-top: 30px;
    width: 85%;
    border-radius: 10px;
    padding: 10px;
    border: inherit;
  }
  .paymentPage button:hover {
    background-color: #85d28c;
    transition: all 0.5s;
    color: #fff;
  
  }
  .logo a img {
    width: 100px;
    padding: 5px 0px;
  }
  .rightCard p {
      margin-bottom: 15px;
  }
.setting .documents {
    background-color: #fff; 
    /* height: 70vh; */
    margin: 0px 30px;
    padding: 30px 20px;
  }
  .setting .documents .inputDocument {
      border-bottom: 1px solid #707070;
      margin-left: 10px;
      padding-left: 5px;
  }
.setting .documents .bgcolor {
    background-color: #F1F1F1;
    padding-bottom: 30px;
    width: 70%;
}

.setting .SettingTab .days {
	padding-top: 3px;
}
.setting .SettingTab .days li {
	text-align: center; 
    font-size: 14px;
    display: inline-block;
    background-color: #DAD9E9;
    color: #707070;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    margin: 0px 10px 0px 0px;
    justify-content: space-between;
    width: 40px;
}
.setting .SettingTab label {
  text-decoration: underline;
  margin-bottom: 0rem;
}
.setting .SettingTab .form-control {
  padding-left: 0;
}
.setting .SettingTab  .editBtn {
  position: absolute;
  right: 15px;
  top: 0px;
  text-align: center;
}
.setting .SettingTab  .editBtn span {
  display: block;
  color: #707070;
  line-height: 1;
  font-size: 14px;
}
.setting .SettingTab  .editBtn i {
  display: block;
  color: #707070;
  line-height: 1.1;
  font-size: 14px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.setting .activePlan { 
  padding: 0px 30px;

}
.setting .activePlan .renewBtn {
  background-color: #6C63FF;
  color: #fff;
  border: inherit;
  font-size: 15px;
  font-weight: 600;
  padding: 12px;
  width: 200px;
  margin-bottom: 25px;

}
.setting .activePlan .activePlanCard {
background-color: #fff;
padding: 30px;
margin-bottom: 20px;
}
.setting .activePlan .activePlanCard p {
  color: #000;
  margin-bottom: 15px;
}
.setting .activePlan .activePlanCard  h6 {
  margin-bottom: 10px;
}
.setting .activePlan .activePlanCard .PlanDetails li {
     display: inline-block;
     background-color: #DAD9E9;
     border-radius: 4px;
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     -ms-border-radius: 4px;
     -o-border-radius: 4px;
     padding: 5px;
     text-align: center;
     width: 140px;
     margin-right: 35px;
     margin-top: 5px;
} 
.setting .activePlan .activePlanCard .PlanDetails span {
  display: block;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}
.setting .activePlan .renewBtn:focus { 
  outline: none;
}
.setting .documents .dropText{
  color: #000000;
  font-size: 20px;
}
.setting .documents .MuiDropzoneArea-root{
  min-height: 100px !important;
  margin-bottom: 20px;
}
.setting .documents .MuiDropzoneArea-root .MuiTypography-h5{
  font-size: 18px;
}
.setting .documents .documentInput{
  padding: 8px;
  font-size: 16px;
  background-color: #ececec;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
  border: none;
}