.msgView{
    margin-bottom: 15px;
}
.msgView .header, .msgView .header .avatarSection, .msgView .header .iconBtnGroup, .groupSettingsModal .header, 
.groupSettingsModal .header .avatarSection, .participants .participantsList .avatarSection{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.groupSettingsModal .header{
    justify-content: space-between;
}
.msgView .header .avatar, .groupSettingsModal .header .avatar, .participants .participantsList .avatar{
    background-color: #323c94;
    color: #ffffff;
    margin-right: 12px;
}
.msgView .header .icon, .groupSettingsModal .header .icon{
    color: #323c94;
}
.msgView button, .groupSettingsModal button{
    outline: none;
}
.msgView .msgView-container{
    padding: 10px 16px;
    height: 280px;
    max-height: 280px;
    overflow: auto;
    overflow-y: scroll;
    margin-bottom: 10px;
}
.msgView .userSent{
    float: right;
    clear: both;
    padding: 7px 15px;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-top: 10px;
    background-color: #d7deeb;
    color: black;
    width: 200px;
    border-radius: 10px;
}
.msgView .friendSent{
    float: left;
    clear: both;
    padding: 7px 15px;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-top: 10px;
    background-color: #707BC4;
    color: white;
    width: 200px;
    border-radius: 10px;
}
.loader{
    display: flex;
    align-items: center;
    justify-content: center;
}
.msgView .msgView-container .timeStamp{
    font-size: 12px;
    float: right;
}
.noChatMsg{
    display: flex;
    align-items: center;
}
.msgView .senderName{
    color: #66CC75;
    font-size: 15px;
    float: left
}
.msgView .chatHistory{
    /* display:flex; */
    align-items: center;
}
.msgView .chatHistory .loadBtn{
    margin-bottom: 5px;
    margin-left: 100px
}
.groupSettingsModal .header, .groupSettingsModal .header .avatarSection{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.groupCreation{
    font-size: 14px;
}
.dialogContent .participants{
    margin-top: 10px;
}
.dialogContent .participants .linePart{
    width: 70%;
    float: left;
}
.groupInfoLoading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dialogContent .participants{
    margin-top: 40px;
}
.dialogContent .participants .participantsList{
    padding: 10px;
}
.dialogContent .participants .participantsList:hover{
    background-color: #eceaea;
}
.dialogContent .participants .participantsList .adminBlock{
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    border: 1px solid #323c94;
    font-size: 14px;
    padding: 5px 8px;
    color: #323c94;
    float: right !important;
    margin-top: 4px;
}
.dialogContent .participants .participantsList .editBox{
    padding: 20px 0;

}
.editBox .linePart{
    margin-bottom: 20px;
}
.editBox .editFooter{
    margin: 20px 0;
}
.editBox .editFooter .createBtn{
    padding: 4px 25px;
    outline: none;
    background-color: #66CC75;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 15px;
}
.editBox .editFooter .cancelBtn{
    padding:4px 25px;
    outline: none;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: #666;
    font-size: 15px;
}
.editBox .modalInput{
    margin-bottom: 15px;
}
