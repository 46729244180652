.addProject{
    padding: 166px 15px 50px 15px;
}
.addProject .heading{
    margin-bottom: 50px;
}
.addProject .stepper{
    background-color: #f3f6fa;
}
.addProject .content{
    background-color: #ffffff;
    padding: 50px 25px 0 25px;
}
.addProject .input{
    border-radius: 4px;
    width: 45%;
    padding: 7px 10px;
    border: 1px solid #b6b7b8;
    font-size: 15px;
    outline: none;
}
.addProject .selectField{
    border-radius: 4px;
    width: 35%;
    padding: 7px 10px;
    border: 1px solid #b6b7b8;
    font-size: 15px;
    outline: none;
}
.addProject .label{
    color: #000000;
    font-size: 17px;
}
.addProject .labelText{
    font-size: 15px;
}
.addProject .horizontalLine{
    margin: 27px 0;
    background-color: #e5ebeb;
    width: 100%;
}
.addProject .footerActivity{
    background-color: #f3f6fa;
    padding: 20px;
    margin: 20px -25px 0 -25px;
}
.addProject .footerActivity button{
    margin: 0 10px;
}
.addProject .trackingTable{
    margin-top: 40px;
}
