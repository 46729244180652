.gradBnner {
    background-color: black;
    background-image: linear-gradient(to right, #D8B5FF, #1EAE98);
    height: 100vh;
    border-top-left-radius: 49%;
    border-bottom-left-radius: 49%;
    display: inline-table;
}

.login .hedding .devMode{
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-left: 30px;
    position: absolute;
}
.login .row {
    margin-left: 0;
    margin-right: 0;
}

.hedding {
    text-align: center;
    display: inline-table;
}

.hedding h1 {
    text-transform: uppercase;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    font-family: 'Lora', serif;
    font-weight: 600;
    line-height: 1.5;
}

.gradBnner .form {
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    min-width: 315px;
    padding-left: 60px;
}

.gradBnner .clock {
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    text-align: center;
}

.gradBnner .clockbg {
    background-color: black;
    background-image: linear-gradient(to bottom, #fcfcfc96, #000);
    width: 370px;
    height: 370px;
    border: 10px solid #fff;
    border-radius: 50%;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    cursor: pointer;
}

.gradBnner .clockbg h1 {
    padding-top: 33%;
    font-size: 72px
}

.form-control {
    color: #fff;
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #fff;
}

.login::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.login:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.login::placeholder {
    color: #fff !important;
}

.login input:-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
    color: #fff !important;
}

.btngroup {
    margin-top: 50px;
    background-color: #fff;
    border-radius: 50px;
}

.submitbtn {
    width: 38%;
}

.active,
.active:hover {
    background-color: #000;
    border-radius: 50px;
    color: #fff;
}

.loaderWrapper {
    background: #dedbdb;
    width: 100%;
    opacity: 0.5;
    filter: alpha(opacity=50);
    height: 100%;
    z-index: 15;
    display: flex;
    position: absolute;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: #fff;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: #fff;
    }
}