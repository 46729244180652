.messageList .listAvatar{
    background-color: #323c94;
    color: #ffffff;
}
.messageList .listItem{
    cursor: pointer;
}
.messageList .listItem:hover{
    background-color: #e3e3e6;
}
.messageList ul{
    padding-top: 0;
}
.messageList .chatString{
    font-size: 14px;
}