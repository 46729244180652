.signupDetails {
    background-color: #2C3E50;
    height: 100vh; 
} 

.signupDetails .MuiStepIcon-root.MuiStepIcon-active {
	color: #FFB600 !important;
}
 
.signupDetails .MuiPaper-root {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent !important;
}
.signupDetails .MuiStepIcon-text {
    fill: #030101 !important; 
}
.signupDetails .MuiStepLabel-label.MuiStepLabel-active {
	color: #fff !important;
	display: block !important;
}
.signupDetails .MuiStepLabel-label.MuiStepLabel-completed {
	color: #fff !important;
}
.signupDetails .MuiStepIcon-root {
    color: #fff !important; 
}
.signupDetails .MuiStepLabel-label {
    color: transparent !important;
}
.signupDetails .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
	margin-top: 5px !important;  
}

.signupDetails .MuiStepIcon-root.MuiStepIcon-completed {
    color: #FFB600 !important;
}
 
.signupDetails .maincontent {
max-width: 900px;
margin: 0 auto;
padding-top: 30px;
}
.signupDetails .MuiStepper-alternativeLabel {
	padding-top: 50px !important;
	max-width: 1050px;
	margin: 0 auto;
}
.signupDetails button:focus {
	outline: none !important;
}
.signupDetails .maincontent input {
	padding: 10px;
	width: 100%;
	border-radius: 10px;
	border: none;
	font-size: 18px;
	margin-bottom: 10px;
}
.signupDetails .maincontent  ul li {
	color: #fff;
	margin: 10px 0px;
	font-size: 18px;
}
.signupDetails .maincontent .MuiButton-containedPrimary {
	color: #2C3E50;
	background-color: #fff;
	font-weight: 600;
	margin-right: 20px;
	padding: 8px;
	border-radius: 10px;
	width: 100px;
}
.signupDetails .maincontent .logo {
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}
.signupDetails .maincontent .workingDays {
	background-color: #fff;
	border-radius: 10px;
	padding: 4px;
	color: #707070;
}
.signupDetails .maincontent .workingDays h4 {
	padding-top: 10px;
	padding-left: 10px;
}
.signupDetails .maincontent .workingDays .days {
	padding-top: 3px;
}
.signupDetails .maincontent .workingDays .days li {
	text-align: center; 
    font-size: 14px;
    display: inline-block;
    background-color: #DAD9E9;
    color: #707070;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    margin: 0px 10px;
    justify-content: space-between;
    width: 40px;
}

.signupDetails .maincontent #country {
	width: 100%;
    padding: 10px;
	border-radius: 10px;
	color: #2C3E50;
}