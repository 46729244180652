.projecDetail{
    padding: 53px 0px 0px;
    z-index: 99;
   
 }
 .boxcss{ 
    border: 1px solid slategray;
    margin: 20px; 
    border-radius: 13px;
    height: 70vh;
    width: 100%;
}