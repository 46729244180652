.task { 
    height: 100vh;
    width: 100%; 
    display: block;
    position: relative;
    padding-top: 75px;

}
.task .taskHeadding {
text-align: end;
padding-bottom: 30px;
font-weight: normal;
}

.task  .pendingCard .card-header {
    background-color: #6C63FF;
    background-image: inherit;
    color: #fff;
    font-size: 15px;
    border-radius: inherit !important; 
    -webkit-border-radius: inherit !important;
    -moz-border-radius: inherit !important;
    -ms-border-radius: inherit !important;
    -o-border-radius: inherit !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}
.task .CompletedCard .card-header {
    background-color: #32CD32;
    background-image: inherit;
    color: #fff;
    font-size: 15px;
    border-radius: inherit !important; 
    -webkit-border-radius: inherit !important;
    -moz-border-radius: inherit !important;
    -ms-border-radius: inherit !important;
    -o-border-radius: inherit !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;

}
.task .ProgressCard .card-header {
    background-color: #FF7F50;
    background-image: inherit;
    color: #fff;
    font-size: 15px;
    border-radius: inherit !important; 
    -webkit-border-radius: inherit !important;
    -moz-border-radius: inherit !important;
    -ms-border-radius: inherit !important;
    -o-border-radius: inherit !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}
.task  .pendingCard .card-body, .task .ProgressCard .card-body, .task .CompletedCard .card-body {
    background-color: #F1F1F2; 
}
.task .card{
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}
.task .card .card-body{ 
    height: 65vh;
    overflow: auto;
}
.task .pendingCard, .task .ProgressCard, .task .CompletedCard  {
    margin: 0px 15px;
} 
.task .pendingCard .card-body ul li, .task .ProgressCard ul li, .task .CompletedCard ul li {
    background-color: #FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}
/* width */
.task ::-webkit-scrollbar {
    width: 3px;
}
  
  /* Track */
.task ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  /* Handle */
.task ::-webkit-scrollbar-thumb {
    background: #707070;
  }
  
  /* Handle on hover */
.task ::-webkit-scrollbar-thumb:hover {
    background: #707070;
}
.pendingModal {
    background-color: #fff;
    padding: 30px 30px 10px;
    border: 1px solid #f5f5f5;
}
.pendingModal .peddingHead {
    background-color: #6C63FF;
    border-radius: 10px;
    padding: 6px;
    color: #fff;
    font-size: 15px;
    max-width: 160px;
    text-align: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-bottom: 15px;
}
.pendingModal .btnMark {
margin-left: 10px;
padding: 5px;
width: 140px;
margin-bottom: 10px;
}
.pendingModal .mainHeading {
    max-width: 70%;
    font-weight: normal;
}
.pendingModal .taskRemaining {
    font-size: 15px;
    color: #666;
    margin-top: 5px;
} 
.pendingModal .taskDetail {
margin: 40px 0px;
}

.pendingModal .asignPeople ul li {
    display: inline-block;
    margin-right: 15px;
    text-align: center;
}
.pendingModal .asignPeople ul li img {
     width: 50px; 
    height: 50px;
     border-radius: 50%; 
     -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    -ms-border-radius: 50%;
     -o-border-radius: 50%;
     margin-bottom: 5px;
     margin-top: 5px;
}
.pendingModal .form-control { 
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 0;
    width: 50%;
    margin-bottom: 10px;
    font-size: 18px;
    color: #6c757d;
}

