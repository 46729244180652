.adminTasks { 
    height: 100vh;
    padding: 95px 12px;
}
.adminTasks .active, .adminTasks .active:hover {
    background-color: inherit; 
    color: inherit;
}

.adminTasks .nav-tabs { 
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    font-size: 18px;
}
.adminTasks .nav-link {
    font-size: 15px;
}
.adminTasks .tab-content {
    padding-top: 15px;
}
.adminTasks .taskBtn i, .adminTasks .sortBtn i {
    margin-left: 10px;
}
.adminTasks .taskBtn, .adminTasks .sortBtn {
    background-color: #fff;
    color: #666;
    border-color: #fff;
    border-radius: inherit;
    -webkit-border-radius: inherit;
    -moz-border-radius: inherit;
    -ms-border-radius: inherit;
    -o-border-radius: inherit;
} 

.taskSearch input[type=search] {
    background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    border: solid 1px #ccc;
    padding: 5px 10px 5px 35px;
    width: 100%;
    border-radius: 10em;
    transition: all .5s;
}

.taskSearch input[type=search]:focus {
    width: 100%;
    background-color: #fff;
    border-color: #66CC75;
    -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    -moz-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    box-shadow: 0 0 5px rgba(109, 207, 246, .5);
}

.taskSearch .searchbox {
    display: block;
}
.searchInfo {
    display: inline-flex;
}
.searchInfo .iconInfo  { 
    text-align: center;
    margin-right: 15px;
}
.searchInfo .iconInfo i {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    line-height: 18px;
    color: #707070;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #707070; 
    cursor: pointer;
    font-size: 14px;
}
.searchInfo span {
    color: #707070;
    font-size: 14px;
    display: block;
    line-height: 1;
    cursor: pointer;
}

.adminTasks .card-header {
    background-image: inherit;
    border-radius: 0px !important;
    background-color: #fff;
    margin-top: 0px;
    color: #000;
    border-bottom: none;
    font-size: 15px;
    padding: .3rem .5rem;
    font-weight: 400;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    position: relative;
}
.adminTasks .card {
    background-color: #DAD9E9;
    box-shadow: 6px 5px 12px -1px #888;
    border: none;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 90%;
}
.adminTasks .card-body {
    padding: .3rem 1rem;
}
.actionIcon span {
    color: #707070;
    font-size: 14px;
    display: block;
    line-height: 1;
    cursor: pointer;
}
.actionIcon .iconInfo {
    text-align: center;
    display: inline-block;
    margin: 0px 10px;
}
 .actionIcon .iconInfo i {
    color: #707070;
    font-size: 16px;
 }
 .actionIcon .archiveIcon {
    padding-bottom: 5px;
    width: 16px;
 }
 .adminTasks .card .status {
    position: absolute;
    top: 0;
    right: -30px;
    background-color: #fff;
    height: 100%;
    align-items: center;
    width: 30px;
    display: flex;
    justify-content: center;
    color: #777;
    font-size: 18px;
    font-weight: 600;
 }
.adminTasks .card .completed {
background-color: #32CD32;
color: #fff;
}
.adminTasks .card .pending {
    background-color: #6C63FF;
    color: #fff;
}
.adminTasks .card .inProgress {
    background-color: #FF7F50;
    color: #fff;
}
.allStatusCard {
    height: 66vh;
    overflow-x: hidden;
    overflow-y: auto;

}
.selectSubtasc .form-control {
    width: 100%;
}
.selectSubtasc select {
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 4px;
    margin-bottom: 0px;
    width: 100%;
    color: #6c757d;
    font-size: 18px;
    margin-top: 10px;
    vertical-align: -webkit-baseline-middle;
}