.rightCard{
    margin-top: 10px;
}
.rightCard ul {
    padding-left: 0;
}

.rightCard ul li {
    list-style: none;
    display: inline-flex;
    text-align: left;
}

.listcard {
    background-color: #95989a;
    height: 52px;
    width: 100%;
    border-radius: 22px;
    padding: 0px 0px 0px 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.20), 0 7px 6px rgba(0, 0, 0, 0.18);
}

li.listcard ul li {
    font-size: 16px;
    padding-right: 14px;
    padding-top: 12px;
    white-space: nowrap;
    display: inline-block;
}

.rightCard ul {
    display: contents;
}

.Absent {
    background-color: #87cefa;
    border: 1.5px solid #DC143C;
}

.sundayLeave {
    background-color: #FD6F8D;
}

.Today {
    background-color: #6c63ff;
}

.Present {
    background-color: #87cefa;
}

li.listcard ul li.dateH {
    font-size: 17px !important;
    font-weight: 500;
}

.one {
    width: 50px;
}

.two {
    width: 105px;
}