.overview {
  padding: 165px 15px; 
  height: 100%;

}
.overviewCard {
background-color: #fff;
border: 1px solid #707070;
padding: 10px;
height: 70vh;
border-radius: 10px;
}