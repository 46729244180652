.leaveWrapwer {
    /* width: 100%;  */
    max-height: 525px;
}

.toaster {
    position: absolute;
    z-index: 5;
    width: 20%;
    margin: auto;
    left: 40%;
}

.leaveWrapwer .card-body {
    padding: 20px 0px;
}
.leaveWrapwer select { 
    border: none;
    width: 75%;
    cursor: pointer;
    color: #95989A;
}
.leaveWrapwer select:focus {
    outline: none;
}
.leaveWrapwer .daycount {
    padding: 0px 8px;
}
.leaveWrapwer .DaysTop {
    padding: 0px 0px 20px;
}
.leaveWrapwer label {
    color: #95989A;
}
.btn-outline-secondary {
    border-radius: 50px;
    margin-left: 10px;
}

.datepickerL {
    padding: 20px 0px;
}
.datepicker .Okbutton {
    width: 100%;
    margin: 0;
    border-radius: 0;
    background: #d5dee1;
    color: black;
    font-weight: 600;
    height: 50px;
    border: none;
}
.leaveWrapwer input {
    width: 100%;
    border: inherit;
    border-bottom: 1px solid #95989a;
    padding: 0px 5px;
    font-size: 15px;
    color: #888;
}

.leaveWrapwer input:focus {
    outline: none;
}

.leaveWrapwer .btn {
    width: 101px;
    white-space: nowrap;
}

.datepicker {
    position: absolute;
    z-index: 5;
    box-shadow: 0 20px 15px rgba(0, 0, 0, 0.20), 0 13px 14px rgba(0, 0, 0, 0.18);
    background: #fff;
}

.btn-reset,
.btn-reset:hover {
    background-color: #dc143c;
    color: #fff;
    padding: 8px;
}

.btn-submit,
.btn-submit:hover {
    background-color: #31cd31; 
    color: #fff;
    padding: 6px;
}

.btn-outline-secondary {
    border-color: #999;
}

.btnGroup {
    margin-top: 25px;
}
.downloadLink{
    margin-bottom: 15px !important;
    margin-left: 100px;
}
.leaveForm{
    margin-top: 20px;
}
.history p span {
    margin-right: 20px;
}

.rdr-Sunday {
    background: red;
}

.rdr-Day {
    border-radius: 100%;
    margin: 5px;
}

.is-selected {
    background: blue !important;
}

.is-inRange {
    background: green !important;
}

.leaveWrapwer .activeLeave {
    background: black;
    color: #fff;
} 

.toasterL {
    position: absolute;
    top: 0;
    width: 80%;
    left: 10%;
    z-index: 5;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    background: green;
    color: #fff;
    padding: 8px;
}

.history {
    height: 80px;
    overflow: auto;
}

.approved:before {
    content: ' ';
    background-color: #5aad5a;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: baseline;
}

.rejected:before {
    content: ' ';
    background-color:#DC143C;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: baseline;
}

.Pending:before {
    content: ' ';
    background-color: #666;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: baseline;
}

p {
    white-space: nowrap;
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    color: #999;
    margin-bottom: 5px;
}

.card {
    -webkit-box-shadow: 5px 3px 10px -5px #888;
    -moz-box-shadow: 5px 3px 10px -5px #888;
    box-shadow: 5px 3px 10px -5px #888;
}


/* scrollbar css */


/* width */

::-webkit-scrollbar {
    width: 5px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #5aad5a;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #5aad5a;
}

 .leavetooltipRemark {
    white-space: unset;
    text-overflow: unset;
     overflow: unset;
 }
.leaveWrapwer .dates {
    display: flex;
}
.leaveWrapwer .dates span {
    margin: 0 10px 0 10px;
    padding-top: 2px;
}
.leaveWrapwer .dates input[type='date']{
    width: 50%;
}
.leaveSecCenter {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column; 
    /* justify-content: center;  */
    margin-top: 115px;
}
@media screen and (max-height: 700px){
    .leaveSecCenter{
        margin-top: 75px;
    }
}
.policy, a.policy:hover {
    text-decoration: underline;
    line-height: 1.2;
    font-size: 14px;
    /* font-weight: 600; */
}
 

@media (max-width: 767px) { 
    .dashboard {
        padding: 0px 20px;
    }
  }

.dayCriterionModal{
    background-color: #fff;
    padding: 25px 30px 20px;
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 1px 0 #f5f5f5
}
.dayCriterionModal input {
    background-color: #FFB600;
}

/* .dayCountmodal {
    background-color: #fff;
    padding: 25px 30px 20px;
    box-shadow: -1px -1px #fdfafa;
}
.dayCountmodal .btnDay {
    margin-left: 10px;
    margin-top: 10px;
    width: 100px;
}

.dayCountmodal .table td, .dayCountmodal .table th {
    border: inherit !important;
    font-weight: normal;
}

.dayCountmodal h5 {
margin-bottom: 20px;
} */
 
.dayCriterionModal input[type="radio"]{
   display: none;
 }
 .dayCriterionModal input[type="radio"] + label{
   position: relative;
   display: inline-block;
   padding-left: 1.5em;
   cursor: pointer;
   line-height: 1em;
   -webkit-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
 }
 .dayCriterionModal input[type="radio"] + label:before,
 .dayCriterionModal input[type="radio"] + label:after{
   content: '';
   position: absolute;
   left: 0;
   right: 0;
   top: 0; 
   width: 1.1em;
   height: 1.1em;
   text-align: center; 
   font-family: Times;
   border-radius: 50%;
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
 }
 .dayCriterionModal input[type="radio"] + label:before {
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
   box-shadow: inset 0 0 0 0.2em #BDBDBD, inset 0 0 0 1em #fff;
 } 
 .dayCriterionModal input[type="radio"]:checked + label:before {
   -webkit-transition: all .3s ease;
   transition: all .3s ease;
   box-shadow: inset 0 0 0 0.2em #FFB600, inset 0 0 0 1em #FFB600;
 }
 .dayCountmodal .table { 
    margin-bottom: 3rem; 
}
.dayCountmodal .table  label { 
    margin-bottom: 1.2rem;
}

.statusModalBody h5 {
    font-weight: normal;
}
.dayCountmodal .form-control { 
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 0; 
    margin-bottom: 10px;
    font-size: 20px;
    color: #000;
}
.form-control:focus {
    outline: none !important;
}
form .leaves{
    margin-bottom: 18px;
}
.leaveModalFooter{
    margin-top: 30px;
    text-align: right;
}
.leaveModalFooter .cancelBtn, .confirmModal .cancelBtn{
    padding: 10px 24px;
    background-color: rgb(196, 8, 46);
    color: white;
    font-size: 13px;
    margin: 10px 15px;
    border-radius: 25px;
    outline: none;
    border: none;
}
.leaveModalFooter .submitBtn, .confirmModal .submitBtn{
    padding: 10px 24px;
    background-color: rgb(23, 187, 23);
    color: white;
    font-size: 13px;
    margin: 10px 15px;
    border-radius: 25px;
    outline: none;
    border: none;
}
.confirmModal{
    background-color: #fff;
    padding: 25px 30px 20px;
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 1px 0 #f5f5f5
}
.confirmModal .btns{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 30px;
}
.confirmModal h6{
    font-weight: bolder;
    margin-bottom: 35px;
    text-align: center;
}
.confirmModal .head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.confirmModal .text{
    font-size: 16px;
    margin-bottom: 20px;
}
.confirmModal .text.date{
    text-align: right;
}
/* .confirmModal .inform{
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    margin-bottom: 20px;
} */
.confirmModal .form-control{
    min-width: 120;
    max-width: 300;
}
.dayCriterionModal .radioBtns{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.dayCriterionModal .date{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dayCriterionModal .radioBtns .fullD{
    margin: 0 12px;
}
.dayCriterionModal .radioBtns .firstH{
    margin: 0 48px;
}
.dayCriterionModal .radioBtns .secondH{
    margin: 0 28px;
}
.totalLeaves{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    /* margin-top: 20px; */
    padding: 15px;
}
.history-accordion{
    margin-top: 35px
}
.history-accordion-details{
    display: flex;
    flex-direction: column;
}

/* .dayCriterionModal{
    background-color: white;
} */