.newChatForm{
    height: 320px !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-height: 320px !important;
    overflow-y: auto;
    overflow-x: hidden;
}
.newChatForm .form{
    height: 100%;
}
.newChatForm .input{
    border: none;
    border-bottom: 1px solid #a8a5a5;
    padding: 8px 12px;
    font-size: 15px;
    width: 100%;
}
.newChatForm .msgBox{
    border: none;
    border-bottom: 1px solid #a8a5a5;
    border-top: 1px solid #a8a5a5 !important;
    margin-top: 130px;
}
.newChatForm .sendBtn{
    margin: 15px 12px;
    width: 70%;
    border-radius: 25px;
}
.newChatForm .serverError{
    color: red;
}