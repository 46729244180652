.App {
    text-align: center;
}

#main:fullscreen {
    background-color: #fff;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.rahuwrapper {
    position: absolute;
    bottom: 20px;
}
.dashboard .modal.show .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} 

.react-calendar__month-view__days__day--weekend {
    color: #fd6f8d !important;
    font-weight: 600;
}

.react-calendar__tile--active {
    background-color: #69F0AE !important;
}
/* .userModalDailog .modal.show .modal-dialog  {
    max-width: 60% !important;
}  */