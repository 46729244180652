.trackerProjects{
    padding: 40px 0;
}
.trackerProjects .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.trackerProjects .tabs{
    outline: none;
}
.trackerProjects .projectBlock .avatar{
    width: 50px;
    height: 50px;
    margin: 5px 25px 0 5px;
    /* padding: 5px; */
}
.trackerProjects .team, .trackerProjects .actions{
    font-size: 18px;
    padding-top: 25px;
}
.trackerProjects .projectBlock{
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
}
.trackerProjects .archivedProjects .icons{
    font-size: 200px;
    color: #3863a3;
}
.trackerProjects .archivedProjects{
   padding: 35px;
}
.trackerProjects .archivedProjects p{
    font-size: 18px;
}
 .MuiDialog-paperFullWidth{
    padding: 0 !important;
}
.dialogTitle .heading{
    font-size: 25px;
    color: black;
    /* font-weight: bold; */
}
.dialogTitle, .addProjectDialog .stepper{
    background-color: #f3f6fa;
}
.addProjectDialog .dialogContent{
    margin-top: 40px;
}
.addProjectDialog .dialogContent .input{
    border: 1px solid #898c8f62;
    outline: none;
    border-radius: 5px;
    padding: 8px 10px;
    width: 300px;
}
.addProjectDialog .dialogContent .row{
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiDialog-paperFullWidth{
    height: 550px !important; 
    /* padding: 0 !important; */
}
.footer{
    bottom: 0;
    padding-bottom: 30px;
    padding-right: 30px;
    position: absolute;
    right: 0;
}
.footer button{
    margin: 0 10px;
}
.inviteMembersRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    /* justify-content: center; */
}
.inviteMembersRow .inputFields{
    border: 1px solid #898c8f62;
    outline: none;
    border-radius: 5px;
    padding: 8px 10px;
    width: 60%;
    margin: 0 12px;
}
.inviteMembersRow .inputSelect{
    width: 20%;
}
.userIcon{
    color: #3863a3;
    margin-right: 20px;
    font-size:30px !important;
}
.addMoreFieldsBtn{
    border: none;
    width: 220px;
}
.addMoreFieldsBtn .addIcon{
    margin-right: 10px;
    font-size: 20px;
}
.MuiBox-root-7, .MuiBox-root-7{
    padding: 0;
}
