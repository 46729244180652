.journal {
    padding: 135px 15px;
    height: 100vh;
}
/* .borderDanger {
    border: 1px solid red;
} */
.journaltoday {
    background-color: transparent;
    color: black;
}
.journaltoday .todayDatePicker .react-datepicker-wrapper{
    display: none;
}
.journaltoday .todayDatePicker .react-datepicker-popper  {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(32px, 30px, 0px) !important;
  }
.journaltoday:hover {
    background-color: transparent;
    color: black;
}
.journaltoday p {
    color: unset;
    margin-bottom: 8px;
}
.previewCard {
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 10px;
}
.previewCard .cardlist {
    height: 62vh;
    overflow: auto;
    overflow-x: hidden;
}
.today {
    padding: 12px 0px;
    background-color: #fff;
    border-radius: 10px;
}

.todayone .cardlist {
    padding: 0px 10px;
}
.cardlist {
    height: 66vh;
    overflow: auto;
    overflow-x: hidden;
    margin: 0;
}
.employeecard {
    border-right: 10px;
    padding: 0 14px;
    margin: 5px 0;
    background-color: #dad9e9;
    border-radius: 10px;
    height: 80px;
}
.employeecard:hover {
    cursor: pointer;
}
.name {
    padding: 10px;
    margin: 0;
    font-weight: unset;
}
.time {
    margin: 0;
    padding: 10px;
    text-align: right;
    font-weight: unset;
}
.detail {
    font-weight: unset;
}
.detail2 {
    font-weight: unset;
    text-align: right;
    font-size: 15px;
}
.previewCard h6 {
    padding: 15px 22px 5px;
}
.previewCard p {
    padding: 10px;
    border: 1px solid lightgray;
    margin: 0px 16px;
    width: inherit;
    border-bottom: inherit;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.previewCard span {
    color: red;
    float: right;
    font-size: 15px;
}
.previewCard span:hover {
    cursor: pointer;
}
.project_name_css {
    color: red;
}
.journal_time_admin {
    font-size: 15px;
}
.journal .dropdown-menu {
    height: 350px;
    width: 250px;
    overflow: auto;
    margin: 0;
    padding: 0;
}
.journal .searchbar {
    width: 90%;
    margin: 10px auto;
    height: 30px;
    border: 1px solid black;
    border-radius: 27px;
    text-align: center;
    display: flex;
}
.journal .searchbar:focus {
    outline: none;
}
.journal .show>.btn-primary.dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
}
.journal .show>.btn-primary.dropdown-toggle {
color: #000;
}
.journal .dropdown-toggle {
    background-color: transparent !important;
    color: black;
    border: none;
    font-size: 15px;
}
.journal .journalDatePicker {
    background: transparent;
    border: none;
}

.journal .nav-tabs {
    margin-top: -49px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    font-size: 15px;
}
.journal .tab-content {
    margin-top: 20px;
}
.previewCard .table {
    width: inherit !important;
    margin: 0px 16px 15px;
}
.line_separator {
    padding: 0px 6px;
    color: #999;
    font-size: 16px;
    vertical-align: baseline;
}
.journal div[data-test='table'] {
    width: -webkit-fill-available;
    overflow: hidden;
    max-height: inherit;
}
.multipleCard {
    padding: 10px 0px;
}

.journal th.inlineText, .journal td.inlineText {
    white-space: nowrap;
}
.journal table tr td {
    font-size: 15px;
}

.btn-primary:focus, .btn-primary:focus {
    box-shadow: none;
}
.journal .seachbarfix {
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    padding: 1px;
}
.journal .dropdown-toggle::after {
    display: inline-block;
    margin-left: 8px;
    content: "";
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    vertical-align: middle;
}
