.employeeSetting {
    padding: 150px 15px;
    height: 100vh;
}

.employeeSetting .employementadd h6 {
     padding-bottom: 10px;
}
.employeeSetting  .form-control {
    color: #666;
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #b5afaf;
    padding-left: 0;
}
.employeeSetting .addBtn {
    background-color: #fff;
    padding: 5px 10px;
    border-color: #fff;
    color: #000;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 600;
    
}
.employeeSetting .cardDitailList {
    height: 60vh;
    overflow: auto;
}

.employeeSetting .cardSelect {
    height: 43vh;
    overflow: auto;
}