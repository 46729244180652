.webTracker{
    padding: 40px 0;
}
.webTracker .heading{
    margin-bottom: 40px;
}
.webTracker .projectBlock{
    display: flex;
    flex-direction: row;
}
.webTracker .projectBlock .avatar{
    width: 50px;
    height: 50px;
    margin: 0 35px 0 15px;
}
.webTracker .projectBlock .text{
    font-size: 13px;
}
.webTracker .projectBlock .title{
    font-size: 18px;
    font-weight: bolder;
    color: black;
}
.webTracker .playIcon{
    width: 50px;
    height: 50px;
    color: #03b57d;
}