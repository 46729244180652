.project {
    padding: 140px 15px;
    height: 100vh;
}

.projectform {
    display: flex;
}

.projectname {
    width: 30%;
}

.projectname:focus {
    background-color: transparent;
    border: unset;
    outline: none !important;
}

.project .table {
    display: table;
    border-collapse: collapse;
}

div[data-test='table'] {
    max-height: 66vh;
    overflow: auto;
    background: #fff;
}

.project .table .table-row {
    display: table-row;
}

.project .table .table-cell {
    display: table-cell;
}

.project table tr th,
.project table tr td {
    text-align: center;
    font-size: 15px;
    padding: .55rem;
}

.project table tr th {
    background-color: #fff;
    position: sticky;
    top: -1px;
    left: 0;
}