.leaveDetail {
    padding: 135px 15px;
    height: 100vh;
}

.leaveDetail .tabview {
    margin-top: -49px;
    position: sticky;
    z-index: 1;
    font-size: 15px;
}

.leaveDetail .nav-tabs .nav-item .nav-link:hover {
    color: #000;
    background-color: inherit;
    border-color: inherit;
    border: none;
}

.leaveDetail .nav-tabs .nav-link:focus,
.leaveDetail .nav-tabs .nav-link:hover {
    border: inherit;
}

.leaveDetail .nav-tabs .nav-item {
    margin-bottom: 0px;
    color: #787878;
}

.leaveDetail .nav-tabs .nav-item.active {
    color: #000;
    font-weight: 600;
}

.leaveDetail .active,
.leaveDetail .active:hover {
    background-color: transparent;
    border-radius: 0px;
    color: #000;
}

.leaveDetail .tab-content {
    padding-top: 20px;
}

.leaveDetail .form-control {
    border-bottom: 1px solid #333;
    font-size: 15px;
    padding: 0px 10px 0px 4px;
}

.leaveDetail .form-control:focus {
    background-color: inherit;
    border-color: inherit;
    box-shadow: inherit;
}

.leaveDetail .addbtn {
    border-radius: 8px;
    font-size: 15px;
    padding: 5px 14px;
}

.leaveDetail .table-bordered {
    background-color: #fff;
    width: 100%;
    border: 1px solid #95989a;
}

.leaveDetail .table {
    display: table;
    border-collapse: collapse;
}

.leaveDetail .table .table-row {
    display: table-row;
}

.leaveDetail .table .table-cell {
    display: table-cell;
}

.leaveDetail table tr th,
.leaveDetail table tr td {
    text-align: center;
    font-size: 15px;
    padding: .55rem;
}

.leaveDetail .tabview1 {
    margin-top: 28px;
    border-radius: 8px;
}

.leaveDetail .leaveCard {
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    padding: 0px 15px;
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.20), 0 7px 6px rgba(0, 0, 0, 0.18);
}

.leaveCard ul li {
    vertical-align: middle;
    font-size: 14px;
    list-style: none;
    display: inline-flex;
    color: #666;
    border-right: 1px solid #999;
    line-height: .9;
    padding: 0px 9px;
}

.leaveCard ul li:first-child {
    padding-left: 0px;
}

.leaveCard ul li:last-child {
    border-right: inherit;
}

.leaveDetail .leaveCard p {
    padding: 5px 0px 10px;
    margin-bottom: 0px;
    text-overflow: inherit;
    white-space: inherit;
    width: 100%;
}

.leaveDetail .leaveright .tab-content {
    padding-top: 0px !important;
}

.leaveDetail .leaveright .btn {
    margin-bottom: 15px;
}

.leaveDetail .holidayCard {
    padding: 0px 0px 0px 15px;
}

.leaveright .tab-pane {
    height: 59vh;
    overflow: auto;
}
.fixarea {
    position: sticky;
    left: 0px;
    background: #fff;
    top: 0;
    -webkit-box-shadow: 0 0px 7px #dad9d9;
    -moz-box-shadow: 0 0px 7px #dad9d9;
    box-shadow: 0 0px 7px #dad9d9;
}


.modalCrossButton {
    position: absolute;
    top: -75px;
    right: 0;
    background: transparent;
    border: none;
    font-size: 40px;
}
.modalCrossButton:focus {
    outline: none;
}
.leave_modal {
    padding: 25px;
    background: #dad9e9;
    border-radius: 10px;
}
 .modal-content {
    background-color: inherit;
    border: inherit;

}
.leave_modal p {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
}
.leave_modal textarea{
    width: 100%;
    border-radius: 10px;
    height: 100px;
    padding: 5px;
    background: #fff;
    border: inherit;
}
.leave_modal textarea:focus{
    outline: none;
}
.leave_modal .approve_reject_button {
    width: 100px; 
}
textarea {
    background: transparent;
}
textarea:focus {
    outline: none;
}
/* .modal-dialog {
    max-width: 50%;
    margin: 7rem auto;
}  */
.leave_modal button { 
    margin-left: 10px;
    border-radius: 50px;
    padding: 1px 0PX 3px;
}

.modal-backdrop { 
    backdrop-filter: blur(22px);
    background-color: #ffffff05;
}
.modal-backdrop.show {
    opacity: 1;
}
.btn-reject {
    background-color: #DC143C;
    color: #fff;
    border-radius: 14px !important;
}
.btn-approve {
    background-color: #3CB371;
    color: #fff;
    border-radius: 14px !important;
}
.darkcolor {
color: #000;
}
.btn-approve:hover, .btn-reject:hover {
    color: #fff;
}
.btn.focus, .btn:focus {
    box-shadow: inherit;
}
.leave_modal .leaveStatusHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.leave_modal ul{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 12px;
    justify-content: space-between;
}
.leave_modal ul .dates{
    padding-right: 40px;
}
.leave_modal .leaveAvatar .fullDay{
    left: 140px;
    position: absolute;
}
.leave_modal .leaveAvatar .firstHalf{
    left: 225px;
    position: absolute;
}
.leave_modal .leaveAvatar .secondHalf{
    left: 315px;
    position: absolute;
}

.leave_modal .filledCircle{
    background-color: #fff;
    width: 25px;
    height: 25px;
    border: 2.5px solid #3CB371;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.leave_modal .filledCircle .filledInnerCircle{
    /* padding: 2px; */
    width: 13px;
    height: 13px;
    border-radius: 25px;
    background-color: #3CB371;
}
.leave_modal .emptyCircle{
    background-color: #dad9e9;
    width: 25px;
    height: 25px;
    border: 2.5px solid #666;
    border-radius: 25px;
}