.employeeList {
    padding: 155px 15px 15px;
    height: 100%;
}
.employeeList .btnSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.employeeList .employeename {
    width: 100%;
}

.employeeList .employeemail {
    width: 100%;
}

.employeeList .employeeDOB {
    width: 100%;
}

.employeeList .employee_data {
    height: 60vh;
    overflow: auto;
}

.employeeList .employee_list_header {
    position: sticky;
    top: 0;
    left: 0;
}

.employeeList .buttons {
    width: 45%;
    margin: 0px 2px;
    font-size: 14px;
    display: inline-block;
    padding: 3px 0px;
}

.employeeList .table thead th {
    position: sticky;
    top: -1px;
    background: #fff;
}


.employeeList .addEmployeeDatePicker {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
}
.employeeList .react-datepicker-wrapper {
    width: 100%;
}

.employeeList .dropdown-toggle {
    width: 100%;
    background: transparent;
    border: unset;
}
.employeeList .dropdown-toggle:focus{
    background: transparent;
    border: unset;
}

.employeeList .form-control { 
    border-bottom: 1px solid #666;
    color: #666;
    margin-top: 4px;
    margin: 0;
    padding: 5px;
}
.employeeList .btn-approve {
    width: 100px;
    padding: 3px;
    margin-bottom: 5px;
}
.employeeList input {
    outline: none;
}

.employeeList input[type=search] {
    -webkit-appearance: textfield;
    /* -webkit-box-sizing: content-box; */
    font-family: inherit;
    font-size: 100%;
}

.employeeList input::-webkit-search-decoration,
.employeeList input::-webkit-search-cancel-button {
    display: none;
}

.employeeList input[type=search] {
    background: #fff url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    border: solid 1px #ccc;
    padding: 5px 10px 5px 35px;
    width: 80%;
    border-radius: 10em;
    transition: all .5s;
}

.employeeList input[type=search]:focus {
    width: 90%;
    background-color: #fff;
    border-color: #66CC75;
    -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    -moz-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    box-shadow: 0 0 5px rgba(109, 207, 246, .5);
}

.employeeList .searchbox {
    display: block;
}
.employeeList .addEnrollBtn {
    background-color: #fff;
    color: #000;
    border-color: #fff;
    width: 100px;
    padding: 5px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.addEmployeeModal {
    background-color: #fff;
    border: 1px solid #f5f5f5;
    padding: 20px 15px 15px;
}

/* .addEmployeeModal .form-control {
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: left;
    color: #6c757d;
} */
.addEmployeeModal .react-datepicker-wrapper {
    display: block;
}

.leftEmployeeList .buttons {
width: 60px;
}
.leftEmployeeList .table td, .leftEmployeeList .table th {
    padding: 0.7rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    color: #777;font-weight: normal;

} 
.rightEmployeeDetail .card {
    height: 100%;
}
.rightEmployeeDetail .card-header {
    padding: .55rem;
    background-image: inherit;
    top: inherit;
    margin: inherit;
    margin-bottom: 0;
    color: #666;
    background-color: #fff;
    font-size: inherit;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.rightEmployeeDetail .card-body {
    padding: 8px 15px;
    height: 52vh;
    overflow: auto;
}
.rightEmployeeDetail .empType  {
    color: #777;
    font-size: 14px;
}
.rightEmployeeDetail .userHead {
    border: inherit;
    padding-bottom: inherit;
}

.rightEmployeeDetail .userHead .status {
    color: #777;
    font-weight: normal;
    font-size: 14px;
}
.rightEmployeeDetail .user img {
    width: 35px;
    height: 35px;
    background-color: #dc143c;
    border-radius: 50px;
}
.rightEmployeeDetail .card-header h6 {
    color: #000;
}

.rightEmployeeDetail .form-control { 
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    font-size: 14px;
    color: #6c757d;
}
.rightEmployeeDetail  select {
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 4px;
    margin-bottom: 0px;
    width: 100%;
    color: #6c757d;
    font-size: 14px;
    margin-top: 8px;
    vertical-align: -webkit-baseline-middle;
}
.leftEmployeeList .table th {
    padding: 1rem .7rem;
}

.rightEmployeeDetail .imageBox {
    width: 80px;
    height: 80px;
    border: 1px solid #95989A;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.rightEmployeeDetail ul li p {
    padding: 10px 0px;
}

.addEmplModal{
    padding: 20px 30px;
    width: 350px ;
}
.addEmplModal .form{
    border: none;
    border-bottom: 1px solid #666;
    margin-bottom: 12px;
    outline: none !important;
}
.addEmplModal .form .form-control{
    font-size: 15px;
    outline: none !important;
    color: #666
}
.addEmplModal .submitBtn{
    margin: 10px 0;
    border-radius: 20px;
}
.emplConfirmModal{
    padding: 25px;
    width: 300px;
}
.MuiFormControl-root{
    width: 100%
}
.tabsHeader{
    background-color: #ffffff;
}
.tabsHeader button{
    outline: none;
}
.oldFormBtns .submitBtn, .deleteEmplModal .confirmButtonGroup .submitBtn{
    padding: 5px 32px;
    outline: none;
    background-color: #66CC75;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    margin-bottom: 10px
}
.deleteEmplModal{
    padding: 20px 35px;
}
.deleteEmplModal .confirmButtonGroup{
    margin-top: 30px
}
.deleteEmplModal .confirmButtonGroup .cancelBtn{
    padding: 5px 32px;
    outline: none;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: #666;
}