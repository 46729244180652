ul li {
    list-style: none;
}

.holidayCard {
    background-color: #fff;
    height: 45px;
    width: 100%;
    border-radius: 22px;
    padding: 0px 0px 0px 25px;
    text-align: justify;
    margin-bottom: 15px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.20), 0 7px 6px rgba(0, 0, 0, 0.18);
    vertical-align: middle;
}

li.holidayCard ul li {
    font-size: 14px;
    ;
    padding-right: 25px;
    list-style: none;
    display: inline-flex;
    padding-top: 10px;
}

ul {
    padding-left: 0;
}