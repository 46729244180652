.textBox{
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #ffffff;
    width: 370px;
}
.textBox .input{
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px !important;

}
.textBox .icon{
    color: #323c94;
}
.textBox button{
    outline: none;
}
.mentionInput{
    width: 340px;
    font-size: 18px;
    /* padding: 4px; */
    border: none;
    border-bottom: 2px solid #323c94;
}