.personnel {
  padding: 135px 15px; 
  height: 100vh;
  z-index: 999;
  position: relative;

} 
.personnel .tabview {
  margin-top: -49px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  font-size: 15px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  font-size: 14px;
}
.personnel input {
  outline: none;
}

.personnel input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}

.personnel input::-webkit-search-decoration,
.personnel input::-webkit-search-cancel-button {
  display: none;
}

.personnel input[type=search] {
  background: #fff7f7 url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px #ccc;
  padding: 4px 10px 4px 35px;
  font-size: 14px;
  width: 90px;
  border-radius: 10em;
  transition: all .5s;
}

.personnel input[type=search]:focus {
  width: 150px;
  background-color: #fff;
  font-size: 14px;
  border-color: #66CC75;
  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
  box-shadow: 0 0 5px rgba(109, 207, 246, .5);
}

.personnel .searchbox {
  display: inline-flex;
  float: right;
}
.nav-link:focus {
  outline: none;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.personnel .tab-content {
  margin-top: 25px;
}
.personnel .paginationBotton {
  position: absolute;
  top: 149px;
  left: 230px;
}
.personnel .pagination-sm .page-link {
  font-size: inherit;
  background: transparent;
  cursor: pointer;
}
.personnel.paginationBotton span.fa-angle-left, .personnel .paginationBotton span.fa-angle-right {
  font-size: 26px;
  vertical-align: sub;
  padding-right: 5px;
  color: #999;
}
.personnel .paginationBotton span.fa-angle-right {
  padding-left: 6px;
  padding-right: 0px;
}
.personnel .active, .personnel .active:hover {
  background-color: inherit;
}
.personnel .pagination-sm .page-item:first-child .page-link, .personnel .pagination-sm .page-item:last-child .page-link { 
  border: inherit;
  color: #95989A;
}
.personnel .headwar .nav-tabs .nav-link {
  background-color: #fff;
  text-align: center;
  width: 80px;
  padding: 5px 3px;
  border-radius: 0px;
  border: 1px solid #95989A;
}
.headwar .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #0000FF;
  border: 1px solid #95989A; 
}
.headwar .nav-tabs .nav-link:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.headwar .nav-tabs .nav-link:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
} 
.ProjectWiseCard {
  background-color: #fff;
  border-radius: 10px;
  height: 60vh;
  border: inherit;
  padding: 20px 0px;
}
.ProjectWiseCard .card-header {
  background-image: inherit;
  background-color: #DAD9E9;
  border-radius: 0px !important;
  margin-top: -1px;
  color: #000;
  padding: 10px 6px;
  font-size: 13px;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.ProjectWiseCard .card {
  border-radius: 15px;
  height: 100%;
  text-align: center;
}
.ProjectWiseCard ul li {
  list-style: none;
  color: #000;
  font-size: 13px;
  padding-bottom: 12px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-color: inherit;
}
.cardCircle {
  width: 30px;
    height: 30px;
    background-color: #32CD32;
    border-radius: 50%;
    font-size: 15px; 
    padding: 2px 2px;
    position: absolute;
    right: -10px;
    top: -10px;
}
.mb-25 {
  margin-bottom: 25px;
}
.productDetail .card-body {
  padding: 10px 0px 10px !important;
}
.productDetail p {
  font-size: 14px;
}
.nav-tabs .nav-link:hover {
  border: inherit;
}