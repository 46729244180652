.trackerSidebar{
    margin-top: 75px;
    left: 0;
    position: fixed;
    background-color: #3863a3;
    height: 100%;
    padding: 35px 27px;
    color: #fff;
}
.trackerContent{
    margin-top: 75px;
    /* position: fixed; */
}
.trackerSidebar .sideNav .heading{
    margin-bottom: 75px;
}
.trackerSidebar .sideNav .heading a{
    font-size: 22px !important;
    letter-spacing: 3px;
    color: #ffffff !important;
    font-family: Arial, Helvetica, sans-serif;
}
.trackerSidebar .sideNav .heading .headingIcon{
    font-size: 50px !important;
    letter-spacing: 3px;
    color: #ffffff !important;
    font-family: Arial, Helvetica, sans-serif;
}
.trackerSidebar .sideNav li{
    margin: 20px 0;
}
.trackerSidebar .sideNav li a{
    color: #ffffff !important;
    font-size: 16px !important;
}
.trackerSidebar .sideNav li .icons{
    /* color: rgb(56, 99, 163) ; */
    margin-right: 10px !important;
}