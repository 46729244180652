header {
    padding: 0px 20px;
    background-color: #ffffff;
    z-index: 1;
}

header .nav-content {
    padding-top: 0px;
}

ul {
    margin-bottom: 0;
}

.logout {
    text-align: center;
}
.logout button {
    background-color: transparent;
    border: none;
}

.user {
    display: inline-block;
    padding-right: 10px;
}

.adminHeader {
    background-color: #85d28c;
}

.adminHeader .designation h6 {
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
}

.adminHeader .rightTime {
    text-align: center;
}

.adminHeader .rightTime h5 {
    margin-bottom: 0;
    font-weight: 800;
    line-height: 1.2;
}

.adminHeader .rightTime p {
    margin-bottom: 15px;
}

.adminHeader .rightTime h5,
.adminHeader .rightTime p,
.adminHeader .designation p {
    color: #fff;
}

.adminHeader .nav-content .nav-content-list .nav-content-item a {
    color: #fff;
}

.user img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.designation {
    display: inline-block;
    vertical-align: middle;
    padding-right: 50px;
}

.designation h6,
.designation p {
    margin-bottom: 0;
}

.designation p {
    font-size: 14px;
    color: #666;
}

.nav-content .nav-content-list .nav-content-item span.arebox {
    background-color: #FD6F8D;
    border-radius: 50%;
    border: 2px solid #fff;
    width: 32px;
    height: 32px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    display: block;
    padding: 4px;
    font-size: 11px;
    margin-left: 7px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.30), 0 7px 12px rgba(0, 0, 0, 0.22);
}


.nav-content-list:hover {
    cursor: pointer;
}

.nav-content .nav-content-list .nav-content-item span.arebox:hover {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.30), 0 10px 12px rgba(0, 0, 0, 0.22);
}

@media (max-width: 768px) {
    .nav-content {
        display: none;
        top: 75px;
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        transition: 100ms all linear 0s;
    }
    .nav-content .nav-content-list {
        flex-direction: column;
        align-items: end !important;
        padding: 0 15px;
    }
}

.nav-content .nav-content-list {
    display: inline-flex;
    padding-left: 0;
}

.nav-content .nav-content-list .nav-content-item {
    margin: 5px 10px;
    position: relative;
    transition: 100ms all linear 0s;
    display: flex;
    align-items: center;
}

.nav-content .nav-content-list .nav-content-item .item-arrow {
    position: relative;
    margin-left: 5px;
    top: -3px;
}

@media (max-width: 768px) {
    .nav-content .nav-content-list .nav-content-item .item-arrow {
        display: none;
    }
}

.nav-content .nav-content-list .nav-content-item .products-list {
    display: none;
    position: absolute;
    width: 200px;
    top: 50px;
    border-bottom: 3px solid #545bc4;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 2px 9px 49px -17px rgba(0, 0, 0, 0.10);
    transition: 100ms all linear 0s;
}

.nav-content .nav-content-list .nav-content-item .products-list .products-item {
    padding: 15px 20px;
}

.nav-content .nav-content-list .nav-content-item .products-list .products-item:hover {
    background: #545bc4;
}

.nav-content .nav-content-list .nav-content-item .products-list .products-item:hover a {
    color: #fff;
}

.nav-content .nav-content-list .nav-content-item a {
    color: #666;
    text-decoration: none;
}

.nav-content .nav-content-list .nav-content-item a:hover {
    color: #000;
}

@media (min-width: 768px) {
    .nav-content .nav-content-list .nav-content-item:hover .products-list {
        display: block;
        top: 100%;
    }
}

.nav-content .nav-content-list .nav-content-link {
    padding: 10px 15px;
    border-radius: 3px;
    transition: 100ms all linear 0s;
}

.nav-content .nav-content-list .nav-content-link:hover {
    background: #e6e6e6;
}

.open-menu-mobile {
    position: relative;
    display: block;
    cursor: pointer;
}

@media (min-width: 768px) {
    .open-menu-mobile {
        display: none;
    }
}

.input-menu {
    display: none;
}

.input-menu .fa-times {
    display: none;
}

.input-menu:checked~.nav-content {
    display: block;
}

.fa-bars {
    font-size: 28px;
}

header .active {
    color: #000 !important;
    background-color: inherit !important;
    border-color: inherit !important;
    border: none !important;
    font-weight: 600;
}

.richout {
    display: inline-block;
}

 .arrow {
     margin-left: 10px;
     font-size: 18px !important;
 }
.richout .btn-link {
    color: #fff;
}
.richout .btn-link:hover {
    color: #fff;
    text-decoration: none;
}
.richout .richHead {
    width: 400px;
    text-align: right;
    padding-bottom: 0;
    height: 50px;
}
.richout .content {
    padding: 10px;
    padding-left: 30px;
}
.richout .nav-tabs .nav-item.show .nav-link, .richout .nav-tabs .nav-link {
    font-size: 15px; 
}
.textAlignment {
    text-align: end;
    margin-top: 15px;
}

.content .icon {
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 45px; 
    font-size: 18px;
    color: #d8dae6;
}
.richout .nav-tabs .nav-item.show .nav-link, .richout .nav-tabs .nav-link {
    color: #d8dae6;
}
.richout .nav-tabs .nav-link.active .icon { 
    color: #fff;
}
.content .nav-link {
    padding: 0rem 2rem 0rem 0.4rem;
    text-align: center;
}
.content .tab-pane {
    text-align: left !important;
}
.content .tab-pane {
margin-top: 10px;
}
.content .card-header {
background-color: #F1F1F1;
background-image: inherit;
font-size: 14px;
padding: 10px;
border-radius: 40px !important;
}
.content .card-header i {
    font-size: 16px;
}
.chatboxHead {
    display: flex;
    align-items: center;
}
.chatboxHead li {
    color: #9DAAB5;
    padding-right: 20px;
    cursor: pointer;
}
.content .search { 
    padding: 5px 10px;
    border-radius: 50px; 
    margin-left: 5px;
    position: absolute;
    display: flex;
    right: 0;
    top: 15px;
}
.content .search i {
    color: #fff;
}
.content .search input {
    border: inherit;
    width: 80%;
    color: #fff;
    background-color: transparent;
    text-align: right;
    margin-right: 10px;
    font-size: 16px;
    margin-top: -3px;
}
.content .card { 
    padding: 0px;
    padding-top: 5px;
    background-color: transparent;
    box-shadow: inherit;
    border: inherit;
}
.content .search input:focus {
    outline: none;
}
.content .card-body {
    padding: 0;
}

.chatCalling li {
display: inline-block;
padding: 0px 10px;
cursor: pointer;
}
.chatCalling li i {
    color: #9DAAB5;
    line-height: 40px;
}
.userName {
    display: flex;
}
.userName img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    vertical-align: middle;
    
}
.userName .subHeading {
    font-size: 12px;
}
.userName h6 {
    line-height: 40px;
    margin-left: 10px;
    font-size: 15px;
    color: #fff;
}

.chatbox{
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
}

 
.logo {
    display: flex;
    margin: 10px 0 0 0;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 3em;
    letter-spacing: 7px;
    border-bottom: 1px solid #ccc;
}
.list-wrap {
    width: 100%;
    overflow: auto;
}
.list-wrap .list { 
    display: flex;
    padding: 10px 5px;
    cursor: pointer;
}
.list-wrap .list img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    vertical-align: middle;
    object-fit: cover;
    margin-right: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}
.list-wrap .list .info {
    flex: 1;
}
.list-wrap .list .info .user {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
}
.list-wrap .list .info .text {
    display: flex;
    margin-top: 3px;
    font-size: 14px;
    line-height: 1.3;
    color: #9DAAB5;
    font-weight: 300;
    max-width: 278px;

}
.list-wrap .list .time {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 0.75em;
    padding: 0;
    color: #a9a9a9;
}
.list-wrap .list .count {
    font-size: 12px;
    background: #0000FF;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .7); 
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
}
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.content header {
    height: 76px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    display: flex; 
    align-items: center;
}
.content header img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}
.content header .info {
    flex: 1;
}
.content header .info .user {
    font-weight: 700;
}
.content header .info .time {
    display: flex;
    margin-top: 3px;
    font-size: 0.85em;
    padding: 0;
}
.content header .open {
    display: none;
}
.content header .open a {
    color: #000;
    letter-spacing: 3px;
}
.message-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow: auto;
}
.message-wrap::before {
    content: '';
    margin-bottom: auto;
}
.message-wrap .message-list {
    align-self: flex-start;
    max-width: 70%;
}
.message-wrap .message-list.me {
    align-self: flex-end;
}
.message-wrap .message-list.me .msg {
    background: #bde2f7;
    color: #111;
}
.message-wrap .message-list .msg {
    background: #fff;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .1);
    padding: 10px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.message-wrap .message-list .time {
    text-align: right;
    color: #999;
    font-size: 0.75em;
    padding: 0;
}
.message-footer {
    border-top: 1px solid #ddd;
    background: #eee;
    padding: 10px;
    display: flex;
    height: 60px;
}
.message-footer input {
    flex: 1;
    padding: 0 20px;
    border-radius: 5px;
}

.userProfile .designation {
    text-align: left;
}
.userProfile .designation h6 {
    color: #000;
    font-weight: bolder;
    font-size: 17px; 
}
.userProfile .content1 {
    background-color: #F1F1F1;
    position: fixed;
    top: 5px;
    z-index: 11;
    left: 10px;
    border-radius: 10px;
    padding: 15px 10px;
    max-width: 400px;
}
.userProfile .content1 .status {
    color: #32CD32;
    font-weight: 600;
}
.userProfile .content1 .user {
    margin-left: 10px; 
}
.userHead {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}
.userProfile .content1  .subheading {
    font-size: 13px;
    padding: 0px 0px 5px 0px;
    margin-bottom: 0;
    width: 100%;
}
.userProfile .content1 ul li {
    display: flex;
    padding: 0px 10px;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 15px;
}
.userProfile .content1 .userDetail {
    padding: 10px 0px 0px 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}
.userProfile .content1 .userDetail img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 2px;
    vertical-align: middle;
}
.userProfile .content1 ul li .icon {
    margin: 4px 13px 0px 3px;
}
.userProfile .content1 .user img {
    width: 45px;
    height: 45px; 
}
.userProfile .content1 .designation h6 {
    color: #000;
    font-weight: bold;
    font-size: 16px;
}
.profilemodal {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #f5f5f5;
}
.profilemodal .penIcon {
     float: right;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    cursor: pointer;
}
.profilemodal .btnDay {
    margin-left: 10px;
    width: 120px;
    font-size: 14px;
    padding: 5px 10px;
} 
.userleftSec img {
    margin-bottom: 15px;
}
.profilemodal .form-control { 
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 10px;
    font-size: 14px;
    color: #6c757d;
}
.profilemodal  select {
    background-color: transparent;
    background-clip: padding-box;
    border: inherit;
    border-radius: inherit;
    border-bottom: 1px solid #7c7b7b;
    padding-left: 0;
    padding-bottom: 4px;
    margin-bottom: 0px;
    width: 100%;
    color: #6c757d;
    font-size: 14px;
    margin-top: 8px;
    vertical-align: -webkit-baseline-middle;
}
 
.documentModal {
    background-color: #fff;
    padding: 20px;
    /* border: 1px solid #c0bebe; */
}
.documentForm {
    padding: 2rem 0rem 4rem;
}
.documentForm .form-control {
    color: #666;
    background-color: #F1F1F1;
    background-clip: padding-box;
    border: inherit; 
    width: 70%;
}
 
.form-control:focus, input:focus {
    outline: none !important;
}

.modal-70w {
    max-width: 70% !important;
}
 
.box.arrows .icondown {
    transform: rotate(-180deg);
    transition: all ease-in-out 0.5s ;
    -webkit-transition: all ease-in-out 0.5s ;
    -moz-transition: all ease-in-out 0.5s ;
    -ms-transition: all ease-in-out 0.5s ;
    -o-transition: all ease-in-out 0.5s ;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}
.settingIcon  {
    display: block;
    float: right; 
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.settingIcon span {
display: block;
line-height: 1;
color: #fff;
}
.settingIcon i {
    font-size: 30px;
    color: #fff;
}

.sidenav---sidenav---_2tBP {
    position: absolute;
    top: 0;
    bottom: 0;
    left: inherit !important;
    right: 0;
    z-index: 1006;
    min-width: 64px;
    background: transparent !important;
    transition: min-width 0.2s;
    height: 100vh; 
}

.sidenav---sidenav---_2tBP .content {
    display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .content {
    display: block;
    padding-top: 0px;
}
.sidenav---sidenav---_2tBP .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    background-color: #000 !important;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    background-color: #fff !important;
    transition: inherit;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -ms-transition: inherit;
    -o-transition: inherit;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    max-width: 453px !important;
    background: #525461 !important;
}
 

.content .search ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
  }
  
.content .search  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
  }
  
.content .search ::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
  }

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    width: 18px !important;
} 

.content .nav-tabs .nav-item.show .nav-link, .content .nav-tabs .nav-link.active {
    color: #fff !important;
}
 
h4.reachHead {
    display: inline-block !important;
    font-size: 22px !important;
    margin-bottom: 0;
    margin-top: 18px;
    margin-left: -10px;
    margin-right: 20px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL h4.reachHead {
    color: #fff !important;
}

 

.cmSoon::before {
      content: '';
      position: absolute;
      width: 150px;
      height: 35px;
      background: #31cd31;
      transform: scaley(0);
      transition: 0.5s;
      transform-origin: left;
      -webkit-transform: scaley(0);
      -moz-transform: scaley(0);
      -ms-transform: scaley(0);
      text-align: center;
      -o-transform: scaley(0);
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
}

    .cmSoon:hover::before {
      transform: scaley(1);
      -webkit-transform: scaley(1);
      -moz-transform: scaley(1);
      -ms-transform: scaley(1);
      -o-transform: scaley(1);
} 
.cmstext {
        display: none;
}

.cmSoon:hover .cmstext {
    display: block;
    color: #fff;
    position: absolute;
    width: 150px;
    font-size: 18px;
    font-weight: 600; 
    text-align: center;
  }

.submenu{
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: #f1f1f1;
    padding: 20px;
    position: absolute;
    border-radius: 18px;
    width: 400px;
    height: 350px;
    box-shadow: 0 2px 16px 0 #868686;
}
.submenu .company{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.submenu .company-logo{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-right: 20px;
}
.submenu .company-logo .img-fluid{
    border-radius: 50%;
    width: 100px;
    height: 72px;
}
.submenu .userSec{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.submenu .userSec h6{
    margin-left: 10px;
}
.submenu .userSec .userLogo{
    margin-left: 5px;
}
.submenu ul li{
    display: flex;
    align-items: center;
}
.submenu .submenuNav .navLink{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 5px;
    cursor: pointer;
}
.submenu .submenuNav .navLink h6{
    font-size: 18px;
    margin-top: 8px
}
.submenu .submenuNav .icons{
    font-size: 25px;
    margin: 0 30px 0 20px;
}
.submenu .closeBtn{
    top: 0;
    right: 0;
}
/* .devModeAdmin{
    top: 10px;
    right: 10px;
    position: absolute;
} */
.settingButton{
    top: 0 !important;
    right: 0 !important;
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.settingButton .settingIcon{
    font-size: 35px;
    color: #ffffff;
}
button{
    outline: none;
}
.submenu .submenuNav li a{
    display: flex;
    flex-direction: row;
    align-items: center;
}