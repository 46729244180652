.userjournal {
    padding-top: 85px;
    height: 100vh;
}

.user_journal_header {
    display: flex;
    background-color: #007bff;
    padding: 3px 15px;
    color: #fff;
    font-size: 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.user_journal_header h6 {
    width: 90%;
}
.user_journal_header button {
    float: right;
    margin-left: auto;
    background: transparent;
    border: 1px solid #fff;
    padding: 3px 12px;
    border-radius: 50%;
    color: #fff;
}

.userjournal .history_header {
    background-color: #007bff;
    padding: 5px 15px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    position: sticky;
    top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.border {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.historySec,
.journalSec {
    border: 1px solid #95989A;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0px 0px 10px;
}

.journalSec {
    height: 82vh;
    overflow: auto;
    width: 95%;
}

.userjournal .col1 {
    width: 36%;
    margin: 0 6px;
}

.userjournal .btn-light,
.userjournal .btn {
    white-space: nowrap;
    padding: 0px !important;
    background: transparent;
    border: none;
}

.userjournal .table thead th,
.userjournal .table-bordered td {
    font-size: 15px;
    font-weight: normal !important;
    padding: 10px;
    border-bottom: none;
}

.userjournal .vline {
    vertical-align: text-top;
    padding: 0px 10px;
}

.vdot {
    padding: 0px 5px;
}

.timepicker {
    position: absolute;
    top: 23%;
    left: 0;
}

.project_modal {
    height: 300px;
    overflow: auto;
}

.project_name {
    padding: 10px;
    text-transform: capitalize;
    margin: 0;
}

.project_name:hover {
    cursor: pointer;
    background-color: #fff;
}

.history_data_wrapper {
    overflow: auto;
    height: 75vh;
}

.history_data {
    padding: 8px 18px;
}
.history_data:hover {
    cursor: pointer;
    background: #e6f1ff;
}

.text-muted {
    color: #fff!important;
    font-size: 1rem;
}

.journalSec input[type="text"]:disabled {
    background: transparent;
}

.journalSec input:focus {
    outline: none;
}
.modalCrossButton {
    position: absolute;
    top: -30px;
    right: -30px;
    background: transparent;
    border: none;
}
.leave_modal {
    padding: 12px;
    background: #dad9e9;
}
.leave_modal p {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
}
.leave_modal textarea{
    width: 100%;
    border-radius: 14px;
    height: 100px;
    padding: 5px;

}
.leave_modal textarea:focus{
    outline: none;
}
.leave_modal .approve_reject_button {
    width: 100px;
}
textarea {
    background: transparent;
}
textarea:focus {
    outline: none;
}
.inputBox {
    border-left: inherit !important;
    border-right: inherit !important;
}
#journal_tooltip {
    width: 400px;
}
#journal_tooltip .tooltip-inner{
    width: 100%;
    max-width: unset !important;
}
.journaltooltip {
    width: 100% !important;
    text-align: center;
    z-index: 5;
}
.crossBtn {
    position: absolute;
    top: 5px;
    right: 0;
    background-color: transparent;
    border: none;
}
.crossBtn:focus {
    outline: none;
}
