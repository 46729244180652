.cardList{
    display: flex;
    overflow-x: auto;
}
.boxprocjectDetailcss{
    margin: 0px 13px;
    border-radius: 13px;
    height: 72vh;
    background-color: #ECECF4;
    padding: 20px 36px 0px 20px;
}
.projectStatus{
    margin:22px 1px 35px 0px;
    display: flex;
    justify-content: center;
}
.cardProjectDeatil{
    width: 114px;
    margin-right: 20px;
    padding: 7px;
    margin-top: 30px;
    background-color: #ECECF4;
    border-style: none;
}
.customProgress{
    border-radius: 8px;
    background-color: #FFFFFF;
    border-radius: 13px;
    /* (height of inner div) / 2 + padding */
    padding: 3px;
    
}
.customProgress .inProgress{
    background-color: #32CD32;
    width: 56%;
    /* Adjust with JavaScript */
    height: 23px;
    border-radius: 10px;
}
.customProgressMain{
    border-radius: 8px;
    background-color: #919496;
    border-radius: 13px;
    /* (height of inner div) / 2 + padding */
    padding: 1px;
    
}
.customProgressMain .inProgress{
    background-color: #32CD32;
    width: 56%;
    /* Adjust with JavaScript */
    height: 3px;
    border-radius: 10px;
}
.noOfTask{
    float: right;
    margin-top: -27px;
}
.space{
    margin-top: 34px;
}
.projectStatusCard {
    background-color: #FFFFFF;
    
}
.cardspace{
    margin-top: 16px;
}
.cardStatus{
    padding-bottom: 12px;
    padding-left: 10px;
    
}
.borderRight{
    border-right: 2px solid #A9A9A9;
    padding: 0px 4px 0px 13px;
}
.statusTaskName{
    font-size: 12px;
}

.mileStoneImg{
    width: 4%;
    margin-left: 14%;
}

.trangleIcon{
    font-size: 23px;
} 
.mainProgressHeight{
    width: 87%;
    margin-top: -13px
}
.projectIcons{
    display: block;
    width: 30%;
    margin: 0 auto;
}
.cardName{
    font-size: 83%;
    font-weight: 600
}