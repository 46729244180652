.userSidebar{
    background-color: #fff;
    padding: 155px 0px 0px;
    height: 100vh;   

}
.projectIcon{
    height: 20px;
}
.projectIconDashboard{
    height: 16px;
}
.userProjectName{
    margin-bottom: 8px;
    font-size: large;  
}
.downq{
    transform: rotate(180deg)!important;
}
.pClass{
    font-size: 13px !important;
}
.scroll{
    overflow-y: scroll;
    height: 26vh
}