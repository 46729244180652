 header {
     position: fixed;
     width: 100%;
     z-index: 1;
 }

 .confirmAlert {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     width: 500px !important;
     z-index: 5;
 }
.toast {
    max-width: 500px !important;
    margin: 70px auto;
    z-index: 999;
}
.confirmAlert .confirm {
    white-space: unset;
} 

 .dashboard {
     padding: 0px 35px;
 }

 .calendar {
     padding-left: 0;
     position: sticky;
 }

 .react-calendar { 
     border: none;
     padding: 5px;
 }

 .react-calendar__tile--now,
 .react-calendar__tile--active,
 .react-calendar__tile--rangeStart,
 .react-calendar__tile--rangeEnd,
 .react-calendar__tile--rangeBothEnds {
     border-radius: 100%;
 }
 .react-calendar__tile {
    padding: 0.75em 0.2em; 
}

 .calendar li {
     list-style: none;
     text-align: left;
     display: inline-block;
     padding: 0px 10px 30px 0px;
     color: #666;
     cursor: pointer;
 }
 /* clock */

 .dashclock {
     height: 100vh;
     display: inline-block;
 }

 .dashclock .clock {
     height: 100vh;
     display: table-cell;
     vertical-align: middle;
     color: #fff;
     text-align: center;
 }

 .dashclock .clockbg {
     background-color: black;
     background-image: linear-gradient(to bottom, #fcfcfc96, #000);
     width: 370px;
     height: 370px;
     border: 10px solid #fff;
     border-radius: 50%;
     box-shadow: 0 20px 15px rgba(0, 0, 0, 0.20), 0 13px 14px rgba(0, 0, 0, 0.18);
     cursor: pointer;
 }

 .dashclock .clockbg h1 {
     padding-top: 33%;
     font-size: 72px
 }
 /* right card in dashboard */

 .dashboard .active,
 .dashboard .active:hover {
     background-color: inherit !important;
     color: inherit !important;
 }

 .nav-tabs {
     border: inherit;
 }

 .nav-tabs .nav-link:hover {
     border: none;
 }

 .nav-tabs .nav-item.show .nav-link,
 .nav-tabs .nav-link.active {
     color: #000;
     background-color: inherit;
     border-color: inherit;
     border: none;
     font-weight: 600;
     ;
 }

 a {
     color: #666;
 }

 .nav-link {
     padding: .5rem 1rem .5rem 0rem;
 }

 .dashclock .clockbg.active {
     background-image: linear-gradient(to bottom, #35353596, #007bff);
 }

 .dashclock .clockbg.active:hover {
     background-image: linear-gradient(to bottom, #35353596, #007bff) !important;
 }

 .rightCard { 
     display: inline-block;
     padding-right: 0;
 }

 .rightCard .attSec {
     height: 100vh;
     display: table-cell;
     vertical-align: middle;
     text-align: center;
      
 }
 .calendarSec {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column; 
    /* align-items: center; */
    justify-content: center;
 }

 /* .calendarSec .calender {
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0px !important;
 } */

 abbr[data-original-title],
 abbr[title] {
     text-decoration: None;
     cursor: pointer;
 }

 .dashboard .nav-tabs .nav-link {
     color: #666;
 }

 .react-draggable {
     width: 100px !important;
     position: absolute;
     bottom: 120px;
     left: 70px;
 }

 .rahuicon {
    border: 1px solid gray;
    border-radius: 100%; 
    font-size: 28px; 
    width: 55px;
    line-height: 1.9;
    height: 55px;
    color: #32CD32;
 }

 .Rahu {
     font-size: 15px;
     text-align: center;
     color: #32CD32;
 }

 .rahuwrapper {
     width: 70px;
 }

 .rahuwrapper:hover {
     cursor: pointer;
 }

 .addtask {
     position: sticky;
     padding: 14px 0 4px 0;
     top: 0;
     z-index: 50;
 }

 .taskcard {
     background-color: #fff;
     border-radius: 10px;
     padding: 10px;
     margin-bottom: 10px;
     font-size: 14px;
     text-align: left;
 }

 .taskicons {
     font-size: 20px;
     color: #fff;
     vertical-align: baseline;
     vertical-align: -webkit-baseline-middle;
 }

 .taskicons:hover {
     cursor: pointer;
 }

 .addtaskcard {
     height: 40px;
     background-color: #fff;
     border-radius: 10px;
     border: none;
     font-size: 14px;
 }

 .addtasticonbtn {
     font-size: 20px;
     color: #fff;
     background-color: transparent;
     border: none;
 }

 .rahucontent {
     background-color: #dad9e9;
     position: absolute;
     width: 100%;
     height: 500px;
     bottom: 30px;
     left: 130px;
     border-radius: 20px;
     z-index: 60;
     padding: 0px 10px;
 }

 .addlistwrap {
     height: 400px;
     overflow-x: hidden;
 }

 .triangle-left {
     width: 0;
     height: 0;
     border-top: 18px solid transparent;
     border-right: 30px solid #dad9e9;
     border-bottom: 23px solid transparent;
     z-index: 55;
     position: absolute;
     left: 102px;
     bottom: 70px;
 }
 .calendarlist{
     height: 50vh;
 }
 .holidaylist {
    height: 50vh;
    padding: 10px;
    overflow: auto;
    border-radius: inherit !important;
 }

 /* Hide scrollbar for Chrome, Safari and Opera */
 .holidaylist::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  .holidaylist {
    -ms-overflow-style: none;
  }

  li.holidayCard ul li:nth-child(2) {
    width: 70px;
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #00000069;
    height: 100%;
    z-index: 99;
}
.loader {
    position: absolute;
     transform: translate(-50%, -50%);
     left: 48%;
    z-index: 5;
     top: 42%;
}

.react-calendar { 
    background: transparent !important;
    border: transparent !important; 
    box-shadow: none !important;
}

.sideNav p { 
    color: #000;
}
.modalarea .btn {
padding: 3px;
width: 100px;
margin: 10px 3px;
}