.messageContainer .messageMenuBox{
    background-color: white;
    width: 385px;
    height: 385px;
    /* border: 1px solid black; */
    box-shadow: 0px 5px 10px 0 #575757;
    right: 20px;
    bottom: 0;
    position: fixed;
    z-index: 999;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.messageContainer .messageMenuBox .messageHeader, .writingPanel .header, .expandedWritingPanel .header{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 4px 8px;
    background-color: inherit;
    border-bottom: 1px solid #a8a5a5;
    display: flex;
    align-items: center;
    justify-content: space-between;
} 
.messageContainer .messageMenuBox .messageHeader .avatarContent, .messageContainer .messageFloater .avatarContent{
    display: flex;
    align-items: center;
}
.messageMenuBox .messageHeader .avatarContent .avatar, .messageContainer .messageFloater .avatarContent .avatar{
    margin-right: 10px;
}
.messageMenuBox .messageHeader .heading, .messageContainer .messageFloater .heading, .writingPanel .header .heading, .expandedWritingPanel .header .heading{
    font-size: 16px;
    color: #000000;
}
.messageMenuBox .messageHeader .icon, .messageFloater .icon, .writingPanel .header .buttonSection .icon, .expandedWritingPanel .header .buttonSection .icon{
    color: #323c94;
}
.messageContainer button{
    outline: none;
}
.messageContainer .messageFloater{
    background-color: white;
    width: 385px;
    height: 50px;
    /* border: 1px solid black; */
    box-shadow: 0px 5px 10px 0 #575757;
    right: 20px;
    bottom: 0;
    position: fixed;
    z-index: 999;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.messageContainer .writingPanel{
    background-color: white;
    width: 370px;
    height: 385px;
    /* border: 1px solid black; */
    box-shadow: 0px 5px 10px 0 #575757;
    right: 430px;
    bottom: 0;
    position: fixed;
    z-index: 999;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.messageContainer .writingPanel .header .buttonSection, .messageContainer .expandedWritingPanel .header .buttonSection{
    display: flex;
}
.messageContainer .expandedWritingPanel{
    background-color: white;
    width: 500px;
    height: 500px;
    /* border: 1px solid black; */
    box-shadow: 0px 5px 10px 0 #575757;
    right: 430px;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.messageBody{
    overflow-y: auto;
    height: 340px;
    max-height: 340px;
    padding-top: 0 !important;
}
.messageBody .listItem{
    border-bottom: 1px solid #d1d7e9;
}
.messageBody .listItem:hover{
    background-color: #d1d7e9;
}
.messageBody .listItem .senderAvatar{
    background-color: #323c94;
    color: #ffffff;
}
.writingPanel .msgTextBox{
    bottom: 0;
    position: absolute;
}
.newGroupCreationModal .modalBody .modalLabel{
    color: #000000;
    font-size: 16px;
}
.newGroupCreationModal .modalBody .modalInput{
    margin-bottom: 22px;
    width: 80%;
}
.newGroupCreationModal .modalFooter{
    margin-top: 30px;
}
.newGroupCreationModal .modalFooter .createBtn{
    padding: 8px 32px;
    outline: none;
    background-color: #66CC75;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 18px;
}
.newGroupCreationModal .modalFooter .cancelBtn{
    padding: 8px 32px;
    outline: none;
    border: none;
    border-radius: 20px;
    color: #ffffff;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: #666;
    font-size: 18px;
}
