.trackerReportsAdmin{
    padding: 165px 15px; 
    height: 100%;
}
.trackerReportsAdmin .header p{
    width: 30%;
}
.trackerReportsAdmin .tabsHeader{
    background-color: #ffffff;
}
.trackerReportsAdmin .tabPanel{
    background-color: #ffffff;
    margin-bottom: 50px;
}
.trackerReportsAdmin .bottomSection{
    background-color: #ffffff;
    padding-top: 15px;
    padding-right: 15px;
}
.trackerReportsAdmin .icons{
    color: #218838 !important;
}

.trackerReportsAdmin .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.trackerReportsAdmin .header p{
    font-size: 14px;
    align-items: flex-end;
    text-align: end;
}
.trackerReportsAdmin .btnHeader{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.trackerReportsAdmin .btnHeader .btnGrp{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.trackerReportsAdmin .btnHeader .btnGrp button{
    margin-right: 10px;
}
.trackerReportsAdmin .btnHeader .btnDropdown button{
    background-color: #f0f1f4;
    border: none;
    outline: none;
    color: #000000;
}
.trackerReportsAdmin .btnHeader .btnDropdown button:hover{
    border: 1px solid #20a34c;
    outline: none;
}
.trackerReportsAdmin .btnHeader .btnDropdown span{
    font-size: 15px;
    margin-right: 12px;
}
.trackerReportsAdmin .btnHeader .btnDropdown span svg{
    color: #218838;
    margin-right: 5px;
}
.trackerReportsAdmin .amtText{
    margin: 30px 0;
    font-size: 18px;
}
.trackerReportsAdmin .amtText strong{
    color: #000000;
}
.trackerReportsAdmin .tabs{
    outline: none;
}
.trackerReportsAdmin .bottomSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.trackerReportsAdmin .bottomSection .pdfBtn{
    margin-right: 10px;
}
.trackerReportsAdmin .bottomSection .exportBtns button{
    font-size: 14px;
}
.trackerReportsAdmin .shownBy button{
    background-color: #f0f1f4;
    border: none;
    outline: none;
    color: #000000;
    margin: 20px 0 10px 0;
}
.trackerReportsAdmin .shownBy button:hover{
    border: 1px solid #a1bad0;
    outline: none;
}
.trackerReportsAdmin .tableRow .tableIcon{
    width: 2%;
}
.trackerReportsAdmin .tableRow .tableIcon .iconBtn{
    outline: none;
}
/* .trackerReportsAdmin .tableCollapseRow .collapsibleTable{
    margin-left: 40px;
} */
.tableCollapseRow .employeeCell{
    width: 72%;
    padding-left: 80px;
}
.tableCollapseRow .timeCell{
    width: 8%;
}
.tableCollapseRow .amountCell{
    padding-left: 135px;
}