.projectInfo{
    padding: 165px 0 80px 0; 
}
.projectInfo .header, .projectInfo .header .leftSide{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.projectInfo .header{
    margin-bottom: 50px;
}
.projectInfo .backBtn{
    width: 40px;
    height: 40px;
    margin-right: 15px;
}
.projectInfo .backIcon{
    font-size: 18px;
}
.projectInfo .avatar{
    width: 50px;
    height: 50px;
    margin-right: 15px;
}
.projectInfo .text{
    font-size: 14px;
}
.projectInfo .addEmployeeBtn, .projectInfo .settingsBtn{
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border: 1px solid #218838;
    margin: 0 10px;
}
.projectInfo .groupIcon, .projectInfo .settingsIcon{
    color: #218838;
    font-size: 25px;
}
.projectInfo .infoCard{
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 50px;
    display: flex;
}
.projectInfo .cost, .projectInfo .pending, .projectInfo .outstanding, .projectInfo .budget{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.projectInfo .widthBox{
    min-width: 150px;
}
/* .projectInfo .collpaseBox{
    display: flex;
} */
.projectInfo .collpaseContent{
    margin-left: 30px;
}
.projectInfo .collapseBtn{
    padding: 0 !important;
    margin-left: 30px;
}
.projectInfo .collpaseIcon{
    font-size: 18px;
    color: #218838;
}
.projectInfo .costTitle{
    color: #000000;
    font-size: 13px;
}
.projectInfo .costText{
    color: #000000;
    font-size: 17px;
}
.projectInfo button{
    outline: none;
}
.projectInfo .verticalLine{
    border: none;
    border-left: 2px solid #dfdcdc;
    height: 100px;
    margin: 0 15px;
}
.projectInfo .pendingTitle{
    color: orange;
    font-size: 13px;
}
.projectInfo .outTitle{
    color: #218838;
    font-size: 13px;
}
.projectInfo .budget{
    width: 300px;
}
.projectInfo .bText{
    font-size: 14px;
    color: red;
}

.projectInfo .teamTitle{
    margin: 50px 0 40px 0;
    text-decoration: underline #218838 solid;
}
.projectInfo .activityTable{
    background-color: #ffffff;
}
.projectInfo .activityTable thead th{
    font-size: 13px;
    color: #a0a0a0;
    font-weight: normal;
}
.projectInfo .activityTable tbody .userBlock{
    display: flex;
    align-items: center;
}
.projectInfo .activityTable tbody .user{
    width: 40px;
    height: 40px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d7f5e2;
    color: #218838;
}
.projectInfo .activityTable tbody .userName{
    font-size: 18px;
    color: #000000;
    /* width: 0% !important; */
}
.projectInfo .activityTable tbody .roleTab{
    border: 1px solid #218838;
    padding: 6px;
    color: #218838;
    font-size: 14px;
    border-radius: 25px;
}
.inviteTitle .MuiTypography-h6{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.inviteBody p {
    margin-bottom: 30px;
}
.inviteFooter{
    margin-top: 100px;
}
.inviteFooter button{
    margin: 0 10px;
}