.trackerProjectsAdmin{
    padding: 165px 0 80px 0; 
    height: 100%;
    /* background-color: white; */
}
.trackerProjectsAdmin .header{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}
.trackerProjectsAdmin .tabsHeader, .trackerProjectsAdmin .tabsPanel{
    background-color: white;
    padding: 10px;
}
.trackerProjectsAdmin .budget p{
    margin-top: -15px;
    left: 0;
    position: absolute;
    width: 80%;
    margin-left: 130px;
}
.trackerProjectsAdmin .budget .gaugeChart{
    margin-left: -17px;
}
/* .budget{
    display: flex;
    flex-direction: column;
} */
.trackerProjectsAdmin .archivedProjects .iconsArchived{
    color: #218838 !important;
    font-size: 200px;

}
.trackerProjectsAdmin .projectBlock{
    display: flex;
    align-items: center;
}
.trackerProjectsAdmin .projectBlock .avatar{
    margin-right: 20px;
    width: 45px;
    height: 45px;
}
.trackerProjectsAdmin button{
    outline: none;
}
tbody tr{
    cursor: pointer;
}
