.attebdencewrapper {
    padding: 155px 25px 30px;
    min-height: 100vh;
}

.attebdencewrapper .nav-tabs {
    border: inherit;
    background-color: #fff;
    display: inline-flex;
    border: 1px solid #95989a;
    border-radius: 8PX;
    padding: 0;
    float: left;
}

.attebdencewrapper .nav-link {
    padding: 2px 15px;
    font-size: 14px;
}

.attebdencewrapper .nav-tabs .nav-item {
    margin-bottom: 0px;
}

.attebdencewrapper .nav-tabs .nav-item:nth-child(2) {
    border-right: 1px solid #95989a;
    border-left: 1px solid #95989a;
    border-radius: 0;
}

.attebdencewrapper .nav-tabs .nav-item:nth-child(2).active {
    color: #fff;
    background-color: blue;
    border-color: inherit;
    border: none;
    border-radius: 0;
    font-weight: 600;
}

.attebdencewrapper .nav-tabs .nav-item:nth-child(2):hover {
    border-radius: 0;
}

.attebdencewrapper .nav-tabs .nav-item:nth-child(1):hover {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.attebdencewrapper .nav-tabs .nav-item:nth-child(3):hover {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.attebdencewrapper .nav-tabs .nav-item.show .nav-link,
.attebdencewrapper .nav-tabs .nav-link:nth-child(3).active {
    color: #fff;
    background-color: blue;
    border-color: inherit;
    border: none;
    border-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 600;
}

.attebdencewrapper .nav-tabs .nav-item.show .nav-link,
.attebdencewrapper .nav-tabs .nav-link:nth-child(1).active {
    color: #fff;
    background-color: blue;
    border-color: inherit;
    border: none;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-weight: 600;
}

.attebdencewrapper input {
    outline: none;
}

.attebdencewrapper input[type=search] {
    -webkit-appearance: textfield;
    /* -webkit-box-sizing: content-box; */
    font-family: inherit;
    font-size: 100%;
}

.attebdencewrapper input::-webkit-search-decoration,
.attebdencewrapper input::-webkit-search-cancel-button {
    display: none;
}

.attebdencewrapper input[type=search] {
    background: #fff7f7 url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    border: solid 1px #ccc;
    padding: 2px 10px 2px 35px;
    width: 90px;
    border-radius: 10em;
    transition: all .5s;
}

.attebdencewrapper input[type=search]:focus {
    width: 150px;
    background-color: #fff;
    border-color: #66CC75;
    -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    -moz-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
    box-shadow: 0 0 5px rgba(109, 207, 246, .5);
}

.attebdencewrapper .searchbox {
    display: inline-flex;
    float: right;
}

.attebdencewrapper .tab-content>.active {
    display: block;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #95989a;
    color: #333;
}

td.innerConntent tr td {
    border: none;
    padding: 1px 10px;
    text-align: center;
}

.fixed {
    position: sticky;
    left: -1px;
    background: #fff;
    -webkit-box-shadow: 0 15px 10px #dad9d9;
    -moz-box-shadow: 0 15px 10px #dad9d9;
    box-shadow: 0 15px 10px #dad9d9;
}

.table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
}

.attebdencewrapper ::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}


/* Track */

.attebdencewrapper ::-webkit-scrollbar-track {
    background: #f1f1f1bd;
}


/* Handle */

.attebdencewrapper ::-webkit-scrollbar-thumb {
    background: #85d28c;
}


/* Handle on hover */

.attebdencewrapper ::-webkit-scrollbar-thumb:hover {
    background: #85d28c;
}

.attebdencewrapper .table {
    margin-bottom: 0;
}

.attebdencewrapper .table tr td {
    white-space: nowrap;
}

.paginationBotton {
    display: inline-block;
    padding-left: 40px;
}

.attebdencewrapper .tab-content {
    padding-top: 45px;
}

.montht,
.week {
    height: 66vh;
    overflow: auto;
}

.paginationBotton {
    position: absolute;
    top: 153px;
    left: 230px;
}
.pagination-sm .page-link {
    font-size: inherit;
    background: transparent;
    cursor: pointer;
}
.paginationBotton span.fa-angle-left, .paginationBotton span.fa-angle-right {
    font-size: 26px;
    vertical-align: sub;
    padding-right: 10px;
    color: #999;
}
.paginationBotton span.fa-angle-right {
    padding-left: 6px;
    padding-right: 0px;
}