.msgView{
    margin-bottom: 15px;
}
.msgView .header, .msgView .header .avatarSection{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.msgView .header .avatar{
    background-color: #323c94;
    color: #ffffff;
    margin-right: 12px;
}
.msgView .header .icon{
    color: #323c94;
}
.msgView button{
    outline: none;
}
.msgView .msgView-container{
    padding: 10px 16px;
    height: 280px;
    max-height: 280px;
    overflow: auto;
    overflow-y: scroll;
    margin-bottom: 10px;
}
.msgView .userSent{
    float: right;
    clear: both;
    padding: 7px 15px;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-top: 10px;
    background-color: #d7deeb;
    color: black;
    width: 200px;
    border-radius: 10px;
}
.msgView .friendSent{
    float: left;
    clear: both;
    padding: 7px 15px;
    box-sizing: border-box;
    word-wrap: break-word;
    margin-top: 10px;
    background-color: #707BC4;
    color: white;
    width: 200px;
    border-radius: 10px;
}
.loader{
    display: flex;
    align-items: center;
    justify-content: center;
}
.msgView .msgView-container .timeStamp{
    font-size: 12px;
    float: right;
}